import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './UseAndEarnItem.styles';
//
export interface UseAndEarnItemProps {
  className?: string;
  descriptionClassName?: string;
  Icon: React.FC;
  title: string;
  description: string;
}

export const UseAndEarnItem: VFC<UseAndEarnItemProps> = ({
  className, title, description, Icon, descriptionClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.imageContainer}>
        <Icon />
      </Box>
      <Box className={classes.textContainer}>
        <Typography variant="h3">
          {t(`${title}`)}
        </Typography>
        <Typography className={clsx(classes.description, descriptionClassName, 'text')}>
          {t(`${description}`)}
        </Typography>
      </Box>
    </Box>
  );
};
