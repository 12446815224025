import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const PlusIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9356 8.04261H9.78263V3.88967C9.78263 3.5069 9.47255 3.19751 9.09048 3.19751C8.70841 3.19751 8.39832 3.5069 8.39832 3.88967V8.04261H4.24538C3.86331 8.04261 3.55322 8.352 3.55322 8.73476C3.55322 9.11753 3.86331 9.42692 4.24538 9.42692H8.39832V13.5799C8.39832 13.9626 8.70841 14.272 9.09048 14.272C9.47255 14.272 9.78263 13.9626 9.78263 13.5799V9.42692H13.9356C14.3176 9.42692 14.6277 9.11753 14.6277 8.73476C14.6277 8.352 14.3176 8.04261 13.9356 8.04261Z" fill="#262626" />
    <mask id="mask0_532_644" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="12" height="12">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9356 8.04261H9.78263V3.88967C9.78263 3.5069 9.47255 3.19751 9.09048 3.19751C8.70841 3.19751 8.39832 3.5069 8.39832 3.88967V8.04261H4.24538C3.86331 8.04261 3.55322 8.352 3.55322 8.73476C3.55322 9.11753 3.86331 9.42692 4.24538 9.42692H8.39832V13.5799C8.39832 13.9626 8.70841 14.272 9.09048 14.272C9.47255 14.272 9.78263 13.9626 9.78263 13.5799V9.42692H13.9356C14.3176 9.42692 14.6277 9.11753 14.6277 8.73476C14.6277 8.352 14.3176 8.04261 13.9356 8.04261Z" fill="white" />
    </mask>
    <g mask="url(#mask0_532_644)" />
  </BaseSVGIcon>
);
