// import { UrlObject } from 'url';

import React, { FC, useCallback, useEffect } from 'react';

import { Box } from '@material-ui/core';

import { Footer, Header } from 'containers';
import { MobileNavigation } from 'containers/MobileNavigation';
import { useWalletConnectorContext } from 'services';
import {
  Languages,
  Modals, ModalsInitialState, State, WalletProviders,
} from 'types';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import modalsSelector from 'store/modals/selectors';
import { closeModal } from 'store/modals/reducer';
import { useDispatch } from 'react-redux';
import { getUserClaims } from 'store/claims/actions';
import { MeetYourNftsModal } from 'components/MeetYourNftsModal';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Layout.styles';

export interface LayoutProps {
  // route?: UrlObject | string;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation();

  const address = useShallowSelector<State, string>(
    userSelector.getProp('address'),
  );

  const {
    modalState: { activeModal, nftsImages, txHash },
  } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);

  const dispatch = useDispatch();

  const { connect, disconnect } = useWalletConnectorContext();

  const handleConnectWallet = useCallback(
    async (provider = WalletProviders.metamask) => {
      connect(provider);
    },
    [],
  );

  const handleCloseMeetNftModal = useCallback(() => {
    dispatch(closeModal());
  }, []);

  const disconnectWallet = useCallback(() => {
    disconnect();
  }, []);

  useEffect(() => {
    if (address) {
      dispatch(getUserClaims({ address }));
    }
  }, [address]);

  const handleLanguageChange = useCallback((language:Languages) => {
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    let lang = location.pathname.replace('/', '');
    if(!lang) {
      const lsLang = localStorage.getItem('i18nextLng');
      if (!lsLang || !(lsLang === 'kr' || lsLang === 'en')) {
        lang = Languages.en;
      }else{
        lang = localStorage.getItem('i18nextLng');
      }
    }
    handleLanguageChange(lang as Languages);
  }, [location.pathname]);
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <MeetYourNftsModal
          images={nftsImages}
          open={activeModal === Modals.MeetNfts}
          onClose={handleCloseMeetNftModal}
          txHash={txHash}
        />
        <MobileNavigation
          disconnect={disconnectWallet}
          onConnectWallet={handleConnectWallet}
        />
        <Header
          disconnect={disconnectWallet}
          onConnectWallet={handleConnectWallet}
        />
        {children}
        <Footer />
      </Box>
    </Box>
  );
};
