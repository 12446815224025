import React, { VFC } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { LITEPAPER_LINK } from 'appConstants';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Main.styles';

export interface MainProps {
  className?: string;
}

export const Main: VFC<MainProps> = ({ className }) => {
  const classes = useStyles();
  const { t, i18n: { language } } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)}>
      <Typography variant="h1" className={clsx(classes.title)}>
        {t('MetaRising is a NFT-based C2E Metaverse')}
      </Typography>
      <Typography className={clsx(classes.subTitle, 'bold headText')}>
        {t('C2E (Clean to Earn) is the act of earning rewards through MetaRising' +
              ' by reducing carbon emissions in the real world.')}
      </Typography>

      <Typography className={clsx(classes.description)}>
        {t('Unique NFTs with in-game utility that give their holders priority access to all opportunities in alpha and beta. Play, earn and stake your rewards directly in the metaverse.')}
      </Typography>
      <Button href={LITEPAPER_LINK[language]} target="_blank" rel="noopener norefferer" variant="contained" className={classes.btn}>Litepaper</Button>
    </Box>
  );
};
