import { createTheme } from '@material-ui/core';
import { getMuiButton, getMuiButtonDefaultProps } from './Button';

import { getMuiContainer, getMuiContainerDefaultProps } from './Container';
import { getMuiCssBaseline, getMuiCssBaselineDefaultProps } from './CssBaseline';
import { getMuiGrid, breakpointOptions, getMuiGridDefaultProps } from './Grid';
import { getMuiLinkDefaultProps, getMuiLinkOverride } from './Link';
import { getTypographyOptions } from './Typography';
import { COLOR_RED } from './colors';
import { getMuiIconButton, getMuiIconButtonDefaultProps } from './IconButton';

// eslint-disable-next-line import/no-mutable-exports
export let theme = createTheme({
  palette: {
    type: 'dark',
    error: {
      main: COLOR_RED,
    },
  },
  typography: getTypographyOptions(),
  breakpoints: breakpointOptions,
  spacing: 8,
});

theme = createTheme(theme, {
  props: {
    MuiCssBaseline: getMuiCssBaselineDefaultProps(),
    MuiLink: getMuiLinkDefaultProps(),
    MuiButtonBase: { disableRipple: true },
    MuiContainer: getMuiContainerDefaultProps(),
    MuiGrid: getMuiGridDefaultProps(),
    MuiTypography: getTypographyOptions(),
    MuiButton: getMuiButtonDefaultProps(),
    MuiIconButton: getMuiIconButtonDefaultProps(),
  },
  overrides: {
    MuiCssBaseline: getMuiCssBaseline(theme),
    MuiLink: getMuiLinkOverride(theme),
    MuiContainer: getMuiContainer(theme),
    MuiGrid: getMuiGrid(theme),
    MuiButton: getMuiButton(theme),
    MuiIconButton: getMuiIconButton(),
  },
});
