import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import {
  COLOR_NEW_ACCENT,
  COLOR_NEW_ACCENT_BG, COLOR_NEW_BG,
  COLOR_NEW_BLACK_100,
  COLOR_NEW_BORDER,
  COLOR_NEW_BORDER_2,
  COLOR_NEW_CARD_BG,
  COLOR_NEW_CARD_BG_GRAY,
} from 'theme/colors';
import { flexHelper } from 'utils';
import { theme } from 'theme';

export const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    left: 0,
    top: 0,
    zIndex: 1000,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  head: {
    ...flexHelper('space-between'),
    padding: theme.spacing(2, 2.5, 1.5),
    borderBottom: `1px solid ${COLOR_NEW_BORDER_2}`,
    backgroundColor: COLOR_NEW_BG,
  },
  selected: {
    backgroundColor: COLOR_NEW_ACCENT_BG,
    borderColor: COLOR_NEW_ACCENT,
  },
  btn: {
    width: 50,
    height: 50,
    border: `1px solid ${COLOR_NEW_BORDER_2}`,
    borderRadius: '50%',
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_NEW_ACCENT,
          stroke: COLOR_NEW_ACCENT,
        },
      },
    },
    '&:hover': {
      backgroundColor: COLOR_NEW_ACCENT_BG,
      borderColor: COLOR_NEW_ACCENT,
    },
  },
  menuBtn: {
  },
  walletBtn: {
    background: COLOR_NEW_CARD_BG,
  },
  main: {
    position: 'fixed',
    left: 0,
    top: -80,
    width: '100%',
    transform: 'translateY(-100%)',
    background: COLOR_NEW_CARD_BG_GRAY,
    padding: theme.spacing(6.25, 10.5, 5.5),
    borderRadius: '0 0 14px 14px',
    transition: 'all 0.5s',
    display: 'flex',
    flexDirection: 'column',
  },
  open: {
    top: '0',
    transform: 'translateY(0)',
  },
  navigation: {
    marginTop: theme.spacing(5.5),
  },
  navigationList: {
    ...flexHelper(),
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  closeBtn: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%,20px)',
    background: COLOR_NEW_BLACK_100,
    '&:hover': {
      backgroundColor: COLOR_NEW_BORDER,
    },
  },
  langBtn: {
    color: COLOR_NEW_ACCENT,
    marginTop: theme.spacing(3),
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
}));
