import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const TwitterLogo: React.FC<IconProps> = ({
  ...props
}) => (
  <BaseSVGIcon
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_376_13799)">
      <path
        d="M8.05016 22.2502C17.1045 22.2502 22.0583 14.7469 22.0583 8.24211C22.0583 8.03117 22.0536 7.81554 22.0442 7.6046C23.0079 6.90771 23.8395 6.0445 24.5 5.05554C23.6025 5.45484 22.6496 5.71563 21.6739 5.82898C22.7013 5.21315 23.4705 4.24572 23.8391 3.10601C22.8726 3.6788 21.8156 4.08286 20.7134 4.30085C19.9708 3.51181 18.989 2.98936 17.9197 2.8143C16.8504 2.63923 15.7532 2.82129 14.7977 3.33234C13.8423 3.84339 13.0818 4.65495 12.6338 5.64156C12.1859 6.62816 12.0754 7.73486 12.3195 8.79054C10.3625 8.69233 8.44794 8.18395 6.69998 7.29834C4.95203 6.41274 3.40969 5.16968 2.17297 3.64976C1.5444 4.73349 1.35206 6.01589 1.63503 7.23634C1.918 8.45678 2.65506 9.52369 3.69641 10.2202C2.91463 10.1954 2.14998 9.98492 1.46563 9.60617V9.6671C1.46492 10.8044 1.8581 11.9068 2.57831 12.787C3.29852 13.6672 4.30132 14.2708 5.41625 14.4952C4.69206 14.6934 3.93198 14.7222 3.19484 14.5796C3.50945 15.5577 4.12157 16.4131 4.94577 17.0266C5.76997 17.64 6.76512 17.9808 7.79234 18.0015C6.04842 19.3714 3.89417 20.1144 1.67656 20.1109C1.28329 20.1103 0.890399 20.0861 0.5 20.0387C2.75286 21.484 5.37353 22.2516 8.05016 22.2502Z"
        fill="#FACC14"
      />
    </g>
    <defs>
      <clipPath id="clip0_376_13799">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </BaseSVGIcon>
);
