import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper('center', 'flex-start'),
    gap: '12px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(6),
    },
  },
  container: {
    ...flexHelper(),
    flexDirection: 'column',
    gap: theme.spacing(1.75),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2.75),
    },
  },
  containerCorn: {
    ...flexHelper(),
    flexDirection: 'column',
    gap: theme.spacing(1.75),
    '&>div:first-of-type': {
      padding: 0,
      background: 'none ',
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2.75),
      padding: 0,
      marginLeft: theme.spacing(0),
    },
  },
  text: {
    maxWidth: 250,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));
