export default [
  { inputs: [{ internalType: 'string', name: '_name', type: 'string' }, { internalType: 'string', name: '_symbol', type: 'string' }, { internalType: 'contract IERC20[]', name: 'paymentToken_', type: 'address[]' }, { internalType: 'uint256[]', name: 'price_', type: 'uint256[]' }, { internalType: 'string', name: '_baseURI', type: 'string' }, { internalType: 'address[]', name: '_admin', type: 'address[]' }], stateMutability: 'nonpayable', type: 'constructor' }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]',
    }, {
      indexed: false, internalType: 'uint256[]', name: '_allowedToExist', type: 'uint256[]',
    }],
    name: 'AllowedToExistChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'operator', type: 'address',
    }, {
      indexed: false, internalType: 'bool', name: 'approved', type: 'bool',
    }],
    name: 'ApprovalForAll',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
    }],
    name: 'RoleAdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleGranted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleRevoked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'operator', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'to', type: 'address',
    }, {
      indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]',
    }, {
      indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]',
    }],
    name: 'TransferBatch',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'operator', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'to', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'id', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'TransferSingle',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'string', name: 'value', type: 'string',
    }, {
      indexed: true, internalType: 'uint256', name: 'id', type: 'uint256',
    }],
    name: 'URI',
    type: 'event',
  }, {
    inputs: [], name: 'DEFAULT_ADMIN_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'SIGNER_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'allowedToExist', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address[]', name: 'accounts', type: 'address[]' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }], name: 'balanceOfBatch', outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'baseURILocked', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'contract IERC20', name: 'token', type: 'address' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }, { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }], name: 'buy', outputs: [], stateMutability: 'payable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'bytes', name: 'signature', type: 'bytes' }], name: 'claim', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'claimed', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'exists', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }], name: 'getRoleAdmin', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'contract IERC20', name: 'token', type: 'address' }], name: 'getToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'grantRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'hasRole', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }, { internalType: 'address', name: 'operator', type: 'address' }], name: 'isApprovedForAll', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'lockBaseURI', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address[]', name: 'accounts', type: 'address[]' }, { internalType: 'uint256[][]', name: 'ids', type: 'uint256[][]' }, { internalType: 'uint256[][]', name: 'amounts', type: 'uint256[][]' }], name: 'mintAsOwner', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'paymentToken', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'contract IERC20', name: 'token', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'price', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'pricePerSingle', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'renounceRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'revokeRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }, { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeBatchTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'id', type: 'uint256' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'safeTransferFrom', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }, { internalType: 'uint256[]', name: '_allowedToExist', type: 'uint256[]' }], name: 'setAllowedToExist', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, { internalType: 'bool', name: 'approved', type: 'bool' }], name: 'setApprovalForAll', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'string', name: '_baseURI', type: 'string' }], name: 'setBaseURI', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'contract IERC20[]', name: 'paymentToken_', type: 'address[]' }, { internalType: 'uint256[]', name: 'price_', type: 'uint256[]' }], name: 'setPayment', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bool', name: '_useAddressInBaseURI', type: 'bool' }], name: 'setUseAddressInBaseURI', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'uri', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
  }, {
    inputs: [], name: 'useAddressInBaseURI', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
  },
// eslint-disable-next-line @typescript-eslint/no-explicit-any
] as any[];
