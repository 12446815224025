import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    title: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    benefitsList: {
      ...flexHelper('space-between'),
      marginTop: theme.spacing(7),
      gap: 20,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  });
});
