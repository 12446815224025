import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './AnimatedImages.styles';

export interface AnimatedImagesProps {
  className?: string;
  image: string;
  type: string;
  badge?: string;
}

export const AnimatedImages: VFC<AnimatedImagesProps> = ({
  className,
  image,
  badge,
  type,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        classes.root,
        className,
        classes[type],
      )}
    >
      {badge && (
      <Box className={classes.badge}>
        <Typography className="s headText">{badge}</Typography>
      </Box>
      )}
      <img src={image} alt={type} />
    </Box>
  );
};
