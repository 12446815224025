import { CubeIcon, GamepadIcon, LibraryIcon } from 'theme/icons';
import { UseAndEarnItemProps } from '../UseAndEarnItem/UseAndEarnItem';

export const useAndEarnContent: UseAndEarnItemProps[] = [
  {
    Icon: GamepadIcon,
    title: 'Play-to-Earn',
    description:
            'Get rewarded by simply playing the game. Use NFTs for in-game activities like ranked minigames, crafting legendary NFTs, customizing avatars, breeding pets, and upgrading vehicles. Complete missions and find new NFTs to use in the game or sell it on the marketplace to other players.',
    className: 'game',
  },
  {
    Icon: LibraryIcon,
    title: 'Trade NFTs on Marketplace ',
    description: 'Each MetaRising NFTs will be stored on the blockchain as NFT tokens which can be freely traded on the NFT marketplace.',
    className: 'trade',
  },
  {
    Icon: CubeIcon,
    title: 'Mystery Box',
    description: 'Players can take their chance at obtaining rare NFTs by opening mystery boxes on the NFT marketplace.  Main ways to obtain in-game NFTs are: SeedBox staking and by simply playing the game.',
    className: 'box',
  },
];
