import { call, put, takeEvery } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { erc721Abi } from 'config/abi';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { showNftsMetadataSaga } from 'store/modals/sagas/showNftsMetadata';
import actionTypes from '../actionTypes';
import actionTypesModals from '../../modals/actionTypes';
import { claim } from '../actions';
import { getUserClaimsSaga } from './getUserClaims';

export function* claimSaga({
  type,
  payload: { contractAddress, userAddress, web3Provider },
}: ReturnType<typeof claim>) {
  yield put(apiActions.request(type));
  let toastId: ReactText;
  try {
    const { data } = yield call(baseApi.getDataForClaims, {
      contractAddress,
      userAddress,
    });

    const nftContract = new web3Provider.eth.Contract(
      erc721Abi,
      contractAddress,
    );
    toastId = toast.loading('Claiming...');
    const tx = yield call(
      nftContract.methods.claim(data.token_id, data.signature).send,
      { from: userAddress },
    );

    yield call(showNftsMetadataSaga, {
      type: actionTypesModals.SHOW_NFT_METADATA,
      payload: {
        contractAddress,
        tokensId: [data.token_id],
        txHash: tx.transactionHash,
      },
    });

    toast.update(toastId, {
      render: 'Success',
      isLoading: false,
      type: 'success',
      closeOnClick: true,
    });

    yield call(getUserClaimsSaga, {
      type: actionTypes.GET_USER_CLAIMS,
      payload: {
        address: userAddress,
      },
    });

    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    toast.update(toastId, {
      render: 'Something went wrong',
      isLoading: false,
      type: 'error',
      closeOnClick: true,
    });

    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.CLAIM, claimSaga);
}
