import modals from './modals/reducer';
import user from './user/reducer';
import prices from './prices/reducer';
import mint from './mint/reducer';
import claims from './claims/reducer';
import ui from './ui/reducer';

export default {
  modals,
  user,
  prices,
  mint,
  claims,
  ui,
};
