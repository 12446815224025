export const routes = {
  home: {
    root: '/',
    title: 'Home',
  },
  about: {
    root: '#about',
    title: 'About our NFTs',
  },
  benefits: {
    root: '#benefits',
    title: 'Benefits for holders',
  },
  follow: {
    root: '#follow',
    title: 'Follow us',
  },
};
