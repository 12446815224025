import React, { FC, ReactNode, useMemo } from 'react';

import {
  Box, Dialog, Typography, IconButton, DialogProps,
} from '@material-ui/core';

import { CloseIcon } from 'theme/icons';
import clsx from 'clsx';
import { useStyles } from './Modal.styles';

export interface ModalProps extends DialogProps {
  customTitle?: string | ReactNode;
  size?: 'sm' | 'md' | 'lg',
  open: boolean;
  withCloseIcon?: boolean;
  onClose: () => void;

  titleClassName?: string;
  paperClassName?: string;
  className?: string;
}
//
export const Modal: FC<ModalProps> = (props) => {
  const {
    customTitle = ' ',
    open,
    titleClassName,
    paperClassName,
    className,
    children,
    onClose,
    size = 'md',
    withCloseIcon = true,
  } = props;
  const classes = useStyles();

  const jsxTitle = useMemo(() => (
    typeof customTitle === 'string' ?
      <Typography variant="h3" className={clsx(classes.title)}>{customTitle}</Typography>
      : customTitle),
  [customTitle]);
  return (
    <Dialog
      transitionDuration={{
        enter: 200,
        exit: 0,
      }}
      open={open}
      onClose={onClose}
      {...props}
      classes={{
        root: className,
        paper: clsx(classes.root, classes[size], paperClassName),
      }}
    >
      <Box className={clsx(classes.modalTitle, titleClassName)}>{customTitle && jsxTitle}</Box>
      { withCloseIcon && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
