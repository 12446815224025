/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext, FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import { Subscription } from 'rxjs';
import {
  Chains, State, UserState, WalletProviders,
} from 'types';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import { login } from 'store/user/actions';
import userSelector from 'store/user/selectors';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from 'hooks';
// import { chains } from 'config';
import Web3 from 'web3';
import { chains } from 'config';
import { WalletService } from '../walletService';

interface IContextValue {
  connect: (provider: WalletProviders) => Promise<void>;
  disconnect: () => void;
  walletService: Web3;
}

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC = ({ children }) => {
  const [currentSubsriber, setCurrentSubsciber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const { provider: walletProvider } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    currentSubsriber?.unsubscribe();
    setCurrentSubsciber(null);
    localStorage.removeItem('walletconnect');
  }, [WalletConnect, currentSubsriber, dispatch]);

  const subscriberSuccess = useCallback((data: any) => {
    if (data.name === 'accountsChanged') {
      if(data.network.chainID !== chains[Chains.bsc].chainId) {
        toast.error(
          'You changed to wrong network. Please choose Binance-Smart-Chain',
        );
        return;
      }
      dispatch(login({ address: data.address }));
    }
  }, [dispatch]);

  const subscriberError = useCallback((err: any) => {
    console.error(err);
    if (err.code === 4) {
      toast.error(
        'You changed to wrong network. Please choose Binance-Smart-Chain',
      );
      disconnect();
    }
  }, [disconnect]);

  const connect = useCallback(
    async (provider: WalletProviders) => {
      const chain = Chains.bsc;
      const connected = await WalletConnect.initWalletConnect(provider, chain);
      if (connected) {
        try {
          const accountInfo: any = await WalletConnect.getAccount();
          if(accountInfo.network.chainID !== chains[Chains.bsc].chainId) {
            toast.error(
              'You connected to wrong network. Please choose Binance-Smart-Chain',
            );
            localStorage.removeItem('walletconnect');
            return;
          }
          if (accountInfo.address) {
            dispatch(login({ address: accountInfo.address }));
            dispatch(updateUserState({ provider }));
          }
          if(!currentSubsriber) {
            const sub = WalletConnect.eventSubscribe().subscribe(
              subscriberSuccess,
              subscriberError,
            );
            setCurrentSubsciber(sub as any);
          }
        } catch (error) {
          console.log(error);
          // metamask doesn't installed,
          // redirect to download MM or open MM on mobile
          if (error.code === 4) {
            switch (error.type) {
              case 'MetaMask':
                if(!(window as any).ethereum) {
                  window.open(
                    `https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}/?utm_source=mm`,
                  );
                }
                break;
              case 'Onto':
                window.open('https://onto.app/en/download/');
                break;

              default:
                break;
            }
            console.error(`${error.message.subtitle}: ${error.message.text}`);
          }
        }
      }
    },
    [subscriberError, subscriberSuccess],
  );
  const web3WithoutMetamask = () => {
    const [first] = Object.values(chains['Binance-Smart-Chain'].provider.WalletConnect.provider.rpc.rpc);
    return new Web3(first as any);
  };

  useEffect(() => {
    if (walletProvider && connect) {
      connect(walletProvider);
    }
  }, []);

  return (
    <Web3Context.Provider
      value={{
        connect,
        disconnect,
        walletService: (WalletConnect.Web3() || web3WithoutMetamask()) as any,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
