import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Logo } from 'components';
import { MICROTUBER_LINK } from 'appConstants';
import { useStyles } from './Footer.styles';

export interface FooterProps {
  className?: string;
}

export const Footer: VFC<FooterProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.footer, className)}>
      <Logo className={classes.logo} />
      <Typography className={clsx(classes.about, 'text')}>
        MetaRising is a verified UN Global Vendor. (UN Vendor #521011)
      </Typography>
      <Typography className="text">
        © 2022{' '}
        <a
          href={MICROTUBER_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.webSiteLink}
        >
          MicroTuber
        </a>
        . All Rights Reserved.
      </Typography>
    </Box>
  );
};
