import React from 'react';

import clsx from 'clsx';

import { logo } from 'assets/images';

import { METARISING_LINK } from 'appConstants';
import { useStyles } from './Logo.styles';

export interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <a href={METARISING_LINK} target="_blank" className={clsx(classes.root, className)} rel="noreferrer">
      <img src={logo} alt="Microtuber Logo" />
    </a>
  );
};
