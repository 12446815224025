/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chains, IConnectWallet, IContracts } from 'types';
import { erc1155Abi, erc721Abi, routerAbi } from './abi';
import { isMainnet, PROJECT_ID } from './constants';

export const chains: {
  [key: string]: {
    name: string;
    chainId: number;
    provider: {
      [key: string]: any;
    };
    img?: any;
  };
} = {
  'Binance-Smart-Chain': {
    name: 'Binance-Smart-Chain',
    chainId: isMainnet ? 56 : 97,
    provider: {
      MetaMask: { name: 'MetaMask' },
      Onto: { name: 'Onto' },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [isMainnet ? 56 : 97]: isMainnet
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            },
            chainId: isMainnet ? 56 : 97,
            chains: [isMainnet ? 56 : 97],
            projectId: PROJECT_ID,
            showQrModal: true,
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: string): IConnectWallet => {
  const chain = chains[newChainName];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

// eslint-disable-next-line no-shadow
export enum ContractsNames {
  profile = 'profile',
  scythes = 'scythes',
  vegetables = 'vegetables',
  router = 'router',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.profile]: {
      address: {
        [Chains.bsc]: isMainnet ? '0xAb961913Ce452ffF01d6833D979A33FedEd5D746' : '0x12CE61A928b237425Ef750fE41Bd0B372ebd304a',
      },
      abi: erc721Abi,
    },
    [ContractsNames.scythes]: {
      address: {
        [Chains.bsc]: isMainnet ? '0x53e0ae5381b1a5F256183c2Aec33060d42807467' : '0x72dC8bFae4b4adeF9f8DcE95dCa2DA72A0f698Db',
      },
      abi: erc1155Abi,
    },
    [ContractsNames.vegetables]: {
      address: {
        [Chains.bsc]: isMainnet ? '0x1f8C34D01c78918f4fDbeF46F564bb7b8D18e2C3' : '0xF93363DC193bCA1ed636c59200C1872D530463ed',
      },
      abi: erc1155Abi,
    },
    [ContractsNames.router]: {
      address: {
        [Chains.bsc]: isMainnet ? '0x10ED43C718714eb63d5aA57B78B54704E256024E'
          : '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
      },
      abi: routerAbi,
    },
  },
};
