import React, { VFC } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { followUsLinks } from 'appConstants';
import { useTranslation } from 'react-i18next';
import { useStyles } from './FollowUs.styles';

export interface FollowUsProps {
  className?: string;
}

export const FollowUs: VFC<FollowUsProps> = ({ className }) => {
  const classes = useStyles();
  const { i18n: { language } } = useTranslation();

  return (
    <Box className={clsx(classes.root, className)} id="follow">
      <Typography variant="h1" className={clsx(classes.title)}>
        Follow us
      </Typography>
      <Box className={clsx(classes.links)}>
        {followUsLinks.map((link) => link.href[language] && (
        <Box key={link.href[language]} className={classes.linkWrapper}>
          <Link
            target="_blank"
            className={clsx(classes.link)}
            href={link.href[language]}
          >
            <link.icon width="22.5px" height="22.5px" />
          </Link>
        </Box>
        ))}
      </Box>
    </Box>
  );
};
