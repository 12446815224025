import React, { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

// import { setNotification } from 'utils';
import { CopyIcon } from 'theme/icons';
import { useStyles } from './Copyable.styles';

export type CopyableProps = {
  valueToCopy: string;
  onCopy?: () => void;
  withIcon?: boolean;
  className?: string;
  classNameIcon?: string;
  withBorder?: boolean;
  onlyIconActive?: boolean;
  transparent?: boolean;
  zeroPadding?: boolean;
};

export const Copyable: FC<CopyableProps> = ({
  valueToCopy,
  onCopy = () => {},
  children,
  withIcon,
  className,
  classNameIcon,
  withBorder,
  onlyIconActive = false,
  transparent = false,
  zeroPadding = false,
}) => {
  const classes = useStyles({ withBorder, transparent });
  if (onlyIconActive) {
    return (
      <div
        className={clsx(classes.copyableContainer, className)}
        style={{ padding: zeroPadding ? '0px 52px 0px 0px' : '' }}
      >
        {children || (
          <Typography noWrap className="text">
            {valueToCopy}
          </Typography>
        )}
        <CopyToClipboard
          text={valueToCopy}
          onCopy={() => {
            onCopy();
            // setNotification({
            //   type: 'success',
            //   message: 'Value copied',
            // });
          }}
          className={clsx(classes.icon)}
        >
          <Box>
            <CopyIcon />
          </Box>
        </CopyToClipboard>
      </div>
    );
  }
  return (
    <CopyToClipboard
      text={valueToCopy}
      onCopy={() => {
        onCopy();
        // setNotification({
        //   type: 'success',
        //   message: 'Value copied',
        // });
      }}
    >
      <Box className={clsx(withIcon && classes.withIcon, className)}>
        {children}
        {withIcon && (
          <Box className={clsx(classes.icon, classNameIcon)}>
            <CopyIcon />
          </Box>
        )}
      </Box>
    </CopyToClipboard>
  );
};
