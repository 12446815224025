import {
  DiscordLogo, InstagramLogo, MediumLogo, NaverLogo, TelegramLogo, TwitterLogo, WebsiteLogo,
} from 'theme/icons';

export const LITEPAPER_LINK = {
  en: 'https://metarising.gitbook.io/metarising-en-litepaper-version-1.0/',
  kr: 'https://metarising.gitbook.io/metarising-kr-litepaper-version-1.0/',
};
export const MICROTUBER_LINK = 'https://microtuber.io/';
export const METARISING_LINK = 'https://metarising.io';

export const followUsLinks = [
  {
    href: {
      en: 'https://t.me/metarising',
      kr: 'https://t.me/microtuber',
    },
    icon: TelegramLogo,
  },
  {
    href: {
      en: 'https://discord.gg/metarising',
      kr: 'https://discord.com/invite/metarising',
    },
    icon: DiscordLogo,
  },
  {
    href: {
      en: 'https://twitter.com/playmetarising',
      kr: 'https://twitter.com/Metarisingkorea',
    },
    icon: TwitterLogo,
  },
  {
    href: {
      en: 'https://metarising.io/',
      kr: 'https://metarising.io/',
    },
    icon: WebsiteLogo,
  },
  {
    href: {
      en: 'https://medium.com/@playmetarising_47240',
      kr: 'https://medium.com/@playmetarisingkorea',
    },
    icon: MediumLogo,
  },
  {
    href: {
      en: '',
      kr: 'https://blog.naver.com/microtuber',
    },
    icon: NaverLogo,
  },
  {
    href: {
      en: '',
      kr: 'https://www.instagram.com/metarisingkorea/',
    },
    icon: InstagramLogo,
  },
];
