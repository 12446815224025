import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_NEW_ACCENT, COLOR_NEW_CARD_BG, COLOR_NEW_TEXT, COLOR_NEW_BORDER_2,
} from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles<Theme, { withBorder: boolean; transparent: boolean }>(
  (theme: Theme) => {
    const formatMedia = getFormatMedia(theme);

    return createStyles({
      root: {
        [formatMedia.BREAKPOINT_TABLET]: {},
      },
      copy: {},
      withIcon: {},
      icon: {
        position: 'absolute',
        right: theme.spacing(2),
        top: '50%',
        height: 24,
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        '&:hover, &:active': {
          '& > *': {
            '& > *': {
              stroke: COLOR_NEW_ACCENT,
            },
          },
        },
      },
      copyableContainer: {
        position: 'relative',
        width: '100%',
        minWidth: 0,
        maxWidth: 'fit-content',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: COLOR_NEW_CARD_BG,
        border: `1px solid ${COLOR_NEW_BORDER_2}`,
        borderRadius: theme.spacing(4.75),
        padding: theme.spacing(1.25, 6.25, 1.25, 2.5),
        height: 43,
        cursor: 'pointer',
        '& span': {
          color: `${COLOR_NEW_TEXT} !important`,
        },
        ...flexHelper('normal', 'center'),
      },
    });
  },
);
