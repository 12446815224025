import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { COLOR_NEW_ACCENT, COLOR_NEW_BORDER, COLOR_NEW_CARD_BG } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3),
    background: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER}`,
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: 14,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  title: {
    marginTop: theme.spacing(2),
  },
  benefitsList: {
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(2, 0, 0),

  },
  benefit: {
    ...flexHelper('flex-start'),
    gap: theme.spacing(1),
  },
  circle: {
    width: 5,
    height: 5,
    backgroundColor: COLOR_NEW_ACCENT,
    borderRadius: '50%',
    flexShrink: 0,

    alignSelf: 'flex-start',
    margin: '9.5px 0',
  },
}));
