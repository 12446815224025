import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const NaverLogo: React.FC<IconProps> = ({ ...props }) => (
  <BaseSVGIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M1.6 0C1.6 0 0 0 0 1.6V22.4C0 22.4 0 24 1.6 24H22.4C22.4 24 24 24 24 22.4V1.6C24 1.6 24 0 22.4 0H1.6ZM5.015 5.6H9.795L14.22 12.058V5.6H18.985V18.4H14.205L9.78 11.943V18.4H5.015V5.6Z" fill="#FACC14" />
  </BaseSVGIcon>
);
