/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  INetwork,
  IProvider,
  ISettings,
} from '@amfi/connect-wallet/dist/interface';
import { DialogProps } from '@material-ui/core';

export * from './store';
export interface OptionalClassNameProp {
  className?: string;
}
export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}

export interface IChainConfig {
  name: string;
  id: number;
  rpc: string;
  tx: {
    link: string;
  };
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExp: string;
}

export interface IContracts {
  decimals: number;
  names: string[];
  contracts: {
    [index: string]: {
      address?: {
        [key in Chains]: string;
      };
      abi: any[];
    };
  };
}
// eslint-disable-next-line no-shadow
export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
  onto = 'Onto'
}
// eslint-disable-next-line no-shadow
export enum Languages {
  en = 'en',
  kr='kr'
}
// eslint-disable-next-line no-shadow
export enum Chains {
  bsc = 'Binance-Smart-Chain',
}
export type Nullable<T> = T | null;
export type Optionable<T> = T | undefined;

export type DateLike = string | number | Date;

export type NativeModalProps = DialogProps;
