import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
      margin: theme.spacing(0, -3),
    },
    containerImg: {
      paddingTop: theme.spacing(2),
    },
    img: {
      maxWidth: 260,
      maxHeight: 342,
      borderRadius: 15,
      opacity: 0.5,
      padding: 5,
    },
    active: {
      opacity: 1,
      border: '1.5px solid #FACC14',
    },
    containerBtn: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
      alignItems: 'center',
      gap: theme.spacing(2.25),
    },
    txHashLink: {
      maxWidth: '100%',
    },
    txHash: {
      maxWidth: 390,
    },
    slide: {
      ...flexHelper(),
    },
    link: {
      // color: '#AAAAAA',
      fontSize: '14px',
      // textTransform: 'lowercase',
    },
  });
});
