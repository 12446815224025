import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    title: {
      '&>h3': {
        textAlign: 'center',
      },
    },
    paper: {
      maxWidth: 900,
    },
    // modal: {
    //   display: 'inline-block',
    //   maxWidth: 900,
    //   maxHeight: 620,
    //   margin: 'auto auto',
    //   borderRadius: 20,
    //   backgroundColor: '#353535',
    // },
  });
});
