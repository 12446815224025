import { put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import apiActions from 'store/api/actions';
import { disconnectWalletState, updateUserState } from '../reducer';
import { login } from '../actions';
import actionTypes from '../actionTypes';

export function* loginSaga({
  type, payload: { address },
}:ReturnType<typeof login>) {
  yield put(apiActions.request(type));
  try{
    yield put(updateUserState({
      address,
    }));
    toast.success(
      `Wallet connected: ${address.slice(
        0,
        5,
      )}...${address.slice(-5)}`,
    );
    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    yield put(disconnectWalletState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
}
