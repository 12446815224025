import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { flexHelper } from 'utils';
import { COLOR_NEW_ACCENT_100, COLOR_NEW_BORDER_HEADER } from 'theme/colors';

export const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  headerWrap: {
    position: 'sticky',
    width: '100%',
    zIndex: 999,
    borderBottom: `1px solid ${COLOR_NEW_BORDER_HEADER}`,
  },
  header: {
    width: '100%',
    ...flexHelper('space-between', 'center'),
    padding: theme.spacing(2.5, 0),
  },
  headerButton: {
    width: 'fit-content',
  },
  languageButton: {
    width: 40,
    height: 40,
    padding: 0,
    borderRadius: '50%',
    marginLeft: theme.spacing(1.5),
  },
  btns: {
    ...flexHelper(),
  },
  left: {
    ...flexHelper('normal', 'center'),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  logo: {
    marginRight: theme.spacing(4),
    maxWidth: '160px',
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  navigationList: {
    ...flexHelper('flex-start'),
    gap: '35px',
    listStyle: 'none',
    [theme.breakpoints.down('md')]: {
      gap: '5px',
      justifyContent: 'space-between',
    },
  },
  navigationItem: {
    textDecoration: 'none',
    '&:hover': {
      '&>p': {
        color: COLOR_NEW_ACCENT_100,
      },
    },
  },
  right: {
    marginLeft: theme.spacing(1),
  },
  copyable: {
    // marginRight: theme.spacing(4),
    maxWidth: 230,
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));
