// eslint-disable-next-line no-shadow
export enum Modals {
  ApprovePending = 'ApprovePending',
  ApproveRejected = 'ApproveRejected',
  ApproveSuccess='ApproveSuccess',
  ClaimPending = 'ClaimPending',
  ClaimRejected = 'ClaimRejected',
  ClaimSuccess = 'ClaimSuccess',
  MeetNfts = 'MeetNfts',
  none = '',
}

export interface ModalState {
  activeModal: Modals;
  txHash: string;
  nftsImages: Array<string>;
  open: boolean;
}

export type ModalsInitialState = {
  modalState: ModalState;
};
