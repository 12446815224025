import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { COLOR_NEW_ACCENT, COLOR_NEW_BG } from 'theme/colors';

// @ts-ignore
import poppins300 from '@fontsource/poppins/300.css';
// @ts-ignore
import poppins400 from '@fontsource/poppins/400.css';
// @ts-ignore
import poppins500 from '@fontsource/poppins/500.css';
// @ts-ignore
import poppins700 from '@fontsource/poppins/700.css';

// @ts-ignore
import inter300 from '@fontsource/inter/300.css';
// @ts-ignore
import inter400 from '@fontsource/inter/400.css';
// @ts-ignore
import inter500 from '@fontsource/inter/500.css';
// @ts-ignore
import inter700 from '@fontsource/inter/700.css';

export const getMuiCssBaseline = (theme: Theme): Overrides['MuiCssBaseline'] => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: 6,
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: COLOR_NEW_BG,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: COLOR_NEW_ACCENT,
      borderRadius: 20,
    },
    '@font-face': [poppins300, poppins400, poppins500, poppins700, inter300, inter400, inter500, inter700],
    html: {
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      boxSizing: 'border-box',
    },
    body: {
      overflowX: 'hidden',
      margin: theme.spacing(0),
      backgroundColor: COLOR_NEW_BG,
      overfloxY: 'overlay',
    },
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
