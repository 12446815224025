import React, { VFC } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './ClaimSection.styles';
import { AnimatedImages } from '../AnimatedImages';
import { claimsInfo } from './ClaimSection.helper';

export interface IImage {
  images: string[];
  isProfile?: boolean;
  badge: string;
}
export interface ClaimSectionProps {
  className?: string;
  handleClaimNft: (contractAddress: string) => void;
  availableClaimsAddresses: Array<string>;
  isButtonDisabled?: boolean;
}

export const ClaimSection: VFC<ClaimSectionProps> = ({
  className,
  availableClaimsAddresses,
  handleClaimNft,
  isButtonDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={clsx(classes.root, className)}>
      {availableClaimsAddresses.map((claimAddress) => (
        <Box className={classes.container} key={claimAddress}>
          <AnimatedImages
            image={claimsInfo[claimAddress].images}
            badge={claimsInfo[claimAddress].badge}
            type={claimsInfo[claimAddress].type}
          />
          <Button disabled={isButtonDisabled} variant="contained" onClick={() => handleClaimNft(claimAddress)}>
            Claim
          </Button>
          {claimsInfo[claimAddress].type === 'seeds' && (
            <Typography className={classes.text}>
              {t('Can be redeemed as pack of seeds in MetaRising. (5 USDT value)')}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};
