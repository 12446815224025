import { call, put } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { usdt } from 'config/constants';
import { contractsConfig } from 'config';
import { getTokenAmountDisplay } from 'utils';

export function* getUsdtPriceSaga({
  type,
  payload: { tokenAddress, amountIn, web3Provider },
}) {
  yield put(apiActions.request(type));
  try {
    const path = [tokenAddress, usdt];
    const routerContract = yield new web3Provider.eth.Contract(
      contractsConfig.contracts.router.abi,
      contractsConfig.contracts.router.address['Binance-Smart-Chain'],
    );

    const usdtPriceForAmountIn = yield call(
      routerContract.methods.getAmountsOut(amountIn, path).call,
    );
    const usdtDecimals = 18;

    const formattedAmountOut = getTokenAmountDisplay(
      usdtPriceForAmountIn[1],
      usdtDecimals,
    );

    yield put(apiActions.success(type));
    return formattedAmountOut;
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    return undefined;
  }
}
