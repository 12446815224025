import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './Benefits.styles';
import { benefitsList } from './benefitsList';
import { Benefit } from '../Benefit';

export interface BenefitsProps {
  className?: string;
}

export const Benefits: VFC<BenefitsProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)} id="benefits">
      <Typography variant="h1" className={clsx(classes.title)}>
        {t('Benefits for holders')}
      </Typography>
      <Box className={classes.benefitsList}>
        {benefitsList.map((benefit) => (
          <Benefit
            key={benefit.title}
            Icon={benefit.Icon}
            title={benefit.title}
            benefits={benefit.benefits}
          />
        ))}
      </Box>
    </Box>
  );
};
