import React, { useCallback, SyntheticEvent, VFC } from 'react';

import { CardMedia } from '@material-ui/core';

import { CustomImageProps, Proportions } from './CustomImage.types';

export const CustomImage: VFC<CustomImageProps> = ({
  width,
  proportions = Proportions.p1to1,
  src,
  alt,
  className,
  title,
}) => {
  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement>): void => {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.src = '/noImage.png';
    },
    [],
  );

  return (
    <CardMedia
      style={{
        maxWidth: width,
        aspectRatio: `${proportions}`,
        objectFit: 'contain',
      }}
      className={className}
      onError={handleError}
      component="img"
      src={src}
      alt={alt}
      title={title}
    />
  );
};
