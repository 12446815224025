export const COLOR_RED = '#A60E49';
// NEW
export const COLOR_NEW_BLACK = '#000000';
export const COLOR_NEW_BLACK_100 = '#262626';

export const COLOR_NEW_WHITE = '#FFFFFF';
export const COLOR_NEW_WHITE_SEMI_70 = 'rgba(255, 255, 255, 0.7)';

export const COLOR_NEW_DARK_BLUE = '#222B45';

export const COLOR_NEW_BORDER = '#323438';
export const COLOR_NEW_BORDER_2 = '#505050';
export const COLOR_NEW_BORDER_3 = '#454545';
export const COLOR_NEW_BORDER_HEADER = '#4D4F50';

export const COLOR_NEW_TEXT = '#AAAAAA';

export const COLOR_NEW_HEAD_TEXT = '#F3F3F3';

export const COLOR_NEW_ACCENT = '#FACC14';
export const COLOR_NEW_ACCENT_100 = '#E7B008';

export const COLOR_NEW_BG = '#252525';
export const COLOR_NEW_ACCENT_BG = 'rgba(250, 204, 20, 0.15)';
export const COLOR_NEW_CARD_BG = 'rgba(53, 53, 53, 0.6)';
export const COLOR_NEW_CARD_BG_GRAY = '#353535';
export const COLOR_NEW_CHIPS_BG = '#363132';
export const COLOR_NEW_BOX_SHADOW = 'rgba(250, 204, 20, 0.76)';
