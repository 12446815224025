import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  root: {
    minWidth: '135px',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}));
