import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_NEW_BORDER, COLOR_NEW_CARD_BG } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: COLOR_NEW_CARD_BG,
    borderRadius: '14px',
    border: `1px solid ${COLOR_NEW_BORDER}`,
    padding: theme.spacing(3),
    ...flexHelper('flex-start', 'flex-start'),
    gap: theme.spacing(2),
    width: '100%',
    minHeight: 207,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    width: 48,
    height: 48,
    background: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER}`,
    ...flexHelper('center'),
    borderRadius: '50%',
    flexShrink: 0,
  },
  textContainer: {
    ...flexHelper('flex-start', 'flex-start'),
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  description: {
    lineHeight: '30px',
    fontSize: '16px',
  },
}));
