import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const FarmingToolIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M28 0C43.4 0 56 12.6 56 28C56 43.4 43.4 56 28 56C12.6 56 0 43.4 0 28C0 12.6 12.6 0 28 0Z" fill="#FACC14" />
    <path d="M34.2946 14.5887C35.4245 15.5436 38.1258 18.1255 39.62 22.0931C41.1286 26.0974 41.4131 31.5045 37.6304 38.0548L37.3297 37.8816L37.6313 38.0557C37.5352 38.2227 37.3225 38.2792 37.1556 38.1832C37.0309 38.1114 36.9681 37.9741 36.9842 37.8395C37.0075 37.1978 37.3181 25.9099 30.5837 20.7326L30.5828 20.7335C30.4365 20.6204 30.4042 20.414 30.5065 20.2632L33.7436 14.6578H33.7427C33.8387 14.4909 34.0523 14.4344 34.2183 14.5304C34.247 14.5465 34.2731 14.5663 34.2946 14.5887Z" fill="#424242" />
    <path d="M35.6103 12.8146L17.3933 44.3666H17.3942C17.2981 44.5336 17.0854 44.5901 16.9185 44.4941C16.9113 44.4896 16.9051 44.486 16.8979 44.4815L15.5113 43.681V43.6819C15.3444 43.5859 15.2879 43.3732 15.3839 43.2063C15.3875 43.1991 15.392 43.1928 15.3965 43.1856L33.6135 11.6327H33.6126C33.7086 11.4657 33.9222 11.4092 34.0883 11.5052C34.0954 11.5097 34.1026 11.5133 34.1089 11.5178L35.4954 12.3183V12.3174C35.6624 12.4134 35.7189 12.6261 35.6229 12.7931C35.6193 12.8002 35.6148 12.8074 35.6103 12.8146Z" fill="#363636" />
  </BaseSVGIcon>
);
