import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_NEW_BLACK_100,
  COLOR_NEW_BORDER,
  COLOR_NEW_CARD_BG,
  COLOR_NEW_WHITE,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper('space-between', 'stretch'),
    padding: theme.spacing(3),
    background: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER}`,
    borderRadius: 20,
    gap: 26,
    '&>div:first-of-type>div:first-of-type': {
      maxWidth: '424px',
      maxHeight: '560px',
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      background: 'none',
      border: 'none',
      padding: '0',
    },
  },
  left: {
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      ...flexHelper(),
      flexDirection: 'column-reverse',
    },
  },
  animatedImg: {
    padding: theme.spacing(0),
  },
  switcher: {
    marginTop: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      margin: theme.spacing(0, 0, 3),
    },
  },
  right: {
    background: COLOR_NEW_BLACK_100,
    width: '100%',
    padding: 30,
    borderRadius: 17,
    [theme.breakpoints.down('md')]: {
      background: 'none',
      border: 'none',
      padding: '0',
    },
  },
  title: {
    fontSize: '24px',
    lineHeight: '34px',
  },
  available: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  availableSkeleton: {
    display: 'inline-block',
  },
  availableCounter: {
    color: COLOR_NEW_WHITE,
  },
  price: {
    marginTop: theme.spacing(3),
  },
}));
