import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimsState, IClaimFromBackend } from 'types';

const initialState: ClaimsState = {
  claims: [],
};

export const claimsReducer = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    updateClaims: (state, action: PayloadAction<Array<IClaimFromBackend>>) => ({
      ...state,
      claims: action.payload,
    }),
  },
});

export const { updateClaims } = claimsReducer.actions;

export default claimsReducer.reducer;
