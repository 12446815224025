import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button } from '@material-ui/core';
import { omit } from 'lodash';
import userSelector from 'store/user/selectors';
import { useStyles } from './DisconnectModal.styles';
import { useShallowSelector } from '../../hooks';
import { Copyable } from '../Copyable';

interface DisconnectModalProps extends ModalProps {
  disconnectWallet?: () => void;
  buttonText?: string;
  cancelable?: boolean;
}

export const DisconnectModal: VFC<DisconnectModalProps> = (props) => {
  const {
    open,
    onClose,
    disconnectWallet,
    buttonText = 'Disconnect',
    cancelable = true,
  } = props;
  const classes = useStyles();

  const address = useShallowSelector(userSelector.getProp('address'));
  return (
    <Modal
      open={open}
      onClose={onClose}
      customTitle="Disconnect Wallet?"
      titleClassName={classes.title}
      withCloseIcon={false}
      paperClassName={classes.root}
      size="sm"
      {...omit({ ...props }, 'disconnectWallet')}
    >
      <Copyable
        valueToCopy={address}
        onlyIconActive
        withBorder
        className={classes.copyable}
      />
      <Button
        className={classes.button}
        variant="contained"
        onClick={disconnectWallet || onClose}
      >
        {buttonText}
      </Button>
      {cancelable && (
        <Button
          className={classes.button}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Cancel
        </Button>
      )}
    </Modal>
  );
};
