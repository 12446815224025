import { FarmingToolIcon, ProfilePicIcon } from 'theme/icons';
import { BenefitProps } from '../Benefit';

export const benefitsList:BenefitProps[] = [
  {
    Icon: FarmingToolIcon,
    title: 'Farming Tool',
    benefits: [
      'Whitelist access to game alpha and beta',
      'NFT holders will get airdrops',
      'Whitelist priority to new gen NFTs',
      'Participate in P2E contents',
      '30,000 Limited Quantity',
    ],
  },
  {
    Icon: ProfilePicIcon,
    title: 'Profile Pic',
    benefits: [
      'IDO Whitelist for MRG token',
      'NFT holders will get airdrops',
      'Used as in-game Profile Pic',
      'All Unique',
      '7,777 Limited Quantity',
    ],
  },
];
