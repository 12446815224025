import React, { useState, VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './LabeledSwitch.styles';

export interface LabeledSwitchProps {
  className?: string;
  firstLabel: string;
  secondLabel: string;
  onChange?: () => void;
  isSecondaryVariant?: boolean;
}

export const LabeledSwitch: VFC<LabeledSwitchProps> = ({
  className,
  firstLabel,
  secondLabel,
  onChange,
  isSecondaryVariant = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(true);
  const checkedColor = isSecondaryVariant ? 'accent' : 'black';
  const unCheckedColor = isSecondaryVariant ? 'semi-white' : 'text';
  const handleClick = (value) => {
    setIsChecked(value);
    if (onChange) {
      onChange();
    }
  };
  return (
    <Box className={clsx(classes.root, isSecondaryVariant && classes.secondary, className)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control  */}
      <label className={classes.switch}>
        <Box
          className={clsx(
            classes.checkedBackground,
            !isChecked && classes.checked,
          )}
        />
        <Typography
          className={clsx(isChecked ? checkedColor : unCheckedColor, classes.label)}
        >
          {t(`${firstLabel}`)}
        </Typography>
        <input
          className={classes.input}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleClick(e.target.checked)}
        />
        <Typography
          className={clsx(isChecked ? unCheckedColor : checkedColor, classes.label)}
        >
          {t(`${secondLabel}`)}
        </Typography>
      </label>
    </Box>
  );
};
