import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';
import { COLOR_NEW_WHITE_SEMI_70 } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      ...flexHelper(),
      flexDirection: 'column',
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    title: {
      textAlign: 'center',
      maxWidth: 760,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    subTitle: {
      maxWidth: 1107,
      marginTop: 51,
      textAlign: 'center',
      fontStyle: 'italic',
    },
    description: {
      maxWidth: 991,
      marginTop: 51,
      textAlign: 'center',
      color: COLOR_NEW_WHITE_SEMI_70,
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },
    btn: {
      marginTop: theme.spacing(5),
    },
  });
});
