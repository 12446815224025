import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { erc1155Abi, erc721Abi } from 'config/abi';
import { contractsConfig } from 'config';
import { clearMintState, updateMintState } from '../reducer';
import { getAvailableLeft } from '../actions';
import actionTypes from '../actionTypes';

export function* getAvailableLeftSaga({
  type,
  payload: { web3Provider, isProfile },
}: ReturnType<typeof getAvailableLeft>) {
  yield put(apiActions.request(type));
  try {
    const address = isProfile ?
      contractsConfig.contracts.profile.address['Binance-Smart-Chain'] : contractsConfig.contracts.scythes.address['Binance-Smart-Chain'];

    const tokenContract = isProfile
      ? yield new web3Provider.eth.Contract(erc721Abi, address)
      : yield new web3Provider.eth.Contract(erc1155Abi, address);

    let availableLeft = 0;

    if (isProfile) {
      const allowedToExist = +(yield call(
        tokenContract.methods.allowedToExist().call,
      ));
      const totalAmount = +(yield call(
        tokenContract.methods.TOTAL_AMOUNT().call,
      ));
      const maxAvailable = totalAmount <= allowedToExist ? totalAmount : allowedToExist;
      const totalSupply = +(yield call(
        tokenContract.methods.totalSupply().call,
      ));
      availableLeft = maxAvailable - totalSupply;
    } else {
      const indexes = [0, 1, 2, 3, 4];
      // eslint-disable-next-line max-len
      const availableLeftPromises = indexes.map((index) => tokenContract.methods.allowedToExist(index).call());
      const availableArray = yield all(availableLeftPromises);

      // eslint-disable-next-line max-len
      const maxAvailable = availableArray.reduce((previousValue, currentValue) => +previousValue + +currentValue, 0);
      // eslint-disable-next-line max-len
      const totalSupplyPromises = indexes.map((index) => tokenContract.methods.totalSupply(index).call());
      const totalSupplyArray = yield all(totalSupplyPromises);

      // eslint-disable-next-line max-len
      const totalSupply = totalSupplyArray.reduce((previousValue, currentValue) => +previousValue + +currentValue, 0);
      availableLeft = maxAvailable - totalSupply;
    }
    yield put(
      updateMintState({
        availableLeft,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    yield put(clearMintState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_AVAILABLE_LEFT, getAvailableLeftSaga);
}
