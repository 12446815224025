import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import {
  COLOR_NEW_WHITE,
  COLOR_NEW_TEXT,
  COLOR_NEW_HEAD_TEXT,
  COLOR_NEW_ACCENT,
  COLOR_NEW_BORDER_2,
  COLOR_NEW_BLACK, COLOR_NEW_WHITE_SEMI_70,
} from 'theme/colors';

// eslint-disable-next-line no-shadow
export enum FontWeights {
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightSemiBold = 600,
  fontWeightBold = 700,
}
export const colorVariations = {
  '&.text': {
    color: COLOR_NEW_TEXT,
  },
  '&.headText': {
    color: COLOR_NEW_HEAD_TEXT,
  },
  '&.accent': {
    color: COLOR_NEW_ACCENT,
  },
  '&.gray': {
    color: COLOR_NEW_BORDER_2,
  },
  '&.semi-white': {
    color: COLOR_NEW_WHITE_SEMI_70,
  },
  '&.black': {
    color: COLOR_NEW_BLACK,
  },
};

export const getTypographyOptions = (): TypographyOptions => {
  const boldStyles = {
    '&.regular': {
      fontWeight: FontWeights.fontWeightRegular,
    },
    '&.strong': {
      fontWeight: FontWeights.fontWeightMedium,
    },
    '&.semi-bold': {
      fontWeight: FontWeights.fontWeightSemiBold,
    },
    '&.bold': {
      fontWeight: FontWeights.fontWeightBold,
    },
  };

  const styleVariants = {
    '&.capitalize': {
      textTransform: 'capitalize',
    },
    '&.uppercase': {
      textTransform: 'uppercase',
    },
    '&.center': {
      textAlign: 'center',
    },
  };

  const weightVariants = {
    '&.medium': {
      fontWeight: FontWeights.fontWeightMedium,
    },
    '&.semibold': {
      fontWeight: FontWeights.fontWeightSemiBold,
    },
    '&.bold': {
      fontWeight: FontWeights.fontWeightBold,
    },
  };

  return {

    allVariants: {
      color: COLOR_NEW_WHITE,
      fontWeight: FontWeights.fontWeightRegular,
      textAlign: 'left',
      fontStyle: 'normal',
      fontFamily: '"Inter", sans-serif',
      ...colorVariations,
      ...styleVariants,
      ...weightVariants,
      ...boldStyles,
    },

    h1: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '36px',
      lineHeight: 1.5,
    },
    h2: {
      fontSize: '28px;',
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '24px',
      lineHeight: 1.42,
    },
    h4: {
      fontSize: '20px',
      lineHeight: 1.6,
    },
    h5: {
      fontSize: '18px',
      lineHeight: 1.67,
    },
    h6: {
      fontSize: '16px',
      lineHeight: 2.13,
    },
    button: {
      fontSize: '16px',
      lineHeight: '19px',
      '&.s': {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: FontWeights.fontWeightMedium,
      },
    },
    body1: {
      fontSize: '14px',
      lineHeight: '22px',
      ...boldStyles,

      '&.caption': {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
      },
      '&.l': {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '&.xl': {
        fontSize: '18px',
        lineHeight: '22px',
      },
      '&.xxl': {
        fontSize: '20px',
        lineHeight: '32px',
      },

      '&.s': {
        fontSize: '12px',
        lineHeight: '16px',
      },

    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.5px',
    },
  };
};
