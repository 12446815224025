import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './About.styles';

export interface AboutProps {
  className?: string;
}

export const About: VFC<AboutProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)} id="about">
      <Typography variant="h1" className={clsx(classes.title)}>
        {t('About our NFTs')}
      </Typography>
      <Typography className={clsx(classes.description, 'text xxl')}>
        {t('MetaRising NFTs are BEP721 and BEP1155.  The NFTs can be used in the metaverse and traded on the NFT marketplace. Part of what makes MetaRising so enjoyable is the variety of NFTs available. Type of NFTs that are obtainable are:')}{' '}
        <span className={classes.descriptionHighlighted}>
          {t('Farming Tools, Profile Pics, Avatar Skins, LAND, Pet, Vehicles, and Usable Items.')}
        </span>
      </Typography>
    </Box>
  );
};
