import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_NEW_ACCENT, COLOR_NEW_BORDER_2, COLOR_NEW_CARD_BG, COLOR_NEW_DARK_BLUE, COLOR_NEW_HEAD_TEXT,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper('space-between'),
    gap: theme.spacing(1.5),
    maxWidth: '130px',
    background: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER_2}`,
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1.25, 1.75),
  },
  btn: {
    width: '24px',
    height: '24px',
    backgroundColor: COLOR_NEW_HEAD_TEXT,
    borderRadius: '50%',
    '& span': {
      ...flexHelper('center', 'center'),
    },
    '&:hover': {
      backgroundColor: COLOR_NEW_ACCENT,
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_NEW_DARK_BLUE,
            stroke: COLOR_NEW_DARK_BLUE,
          },
        },
      },
    },
  },
  counterText: {
    width: 22,
  },
}));
