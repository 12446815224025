import React, { VFC } from 'react';
import { Box, Container } from '@material-ui/core';
import { Main } from 'components/Main';
import { NftActionsSection } from 'components/NftActionsSection';
import { Benefits } from 'components/Benefits';
import { About } from 'components/About';
import { UseAndEarn } from 'components/UseAndEarn';
import { FollowUs } from 'components/FollowUs';
import { useStyles } from './Home.styles';

export const Home: VFC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Main />
        <NftActionsSection />
        <Benefits />
        <About />
        <UseAndEarn />
        <FollowUs />
      </Container>
    </Box>
  );
};
