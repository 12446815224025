import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import {
  bgEllipse, animatedBackground, animatedBackgroundReverted,
} from 'assets/images';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: '.8',
      zIndex: -1,
      background: `url(${animatedBackground}), url(${animatedBackgroundReverted}), url(${animatedBackground})`,
      backgroundPosition: 'center 75px, center 1350px, center 2400px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      [theme.breakpoints.down('md')]: {
        color: 'white',
        background: `url(${animatedBackground}), url(${bgEllipse})`,
        backgroundPosition: 'center 75px, center 75px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      },
      [theme.breakpoints.down(700)]: {
        display: 'none',
      },
    },
  },
  content: {
    position: 'relative',
    overflowX: 'hidden',
  },
}));
