import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import {
  COLOR_NEW_BG,
  COLOR_NEW_BORDER,
  COLOR_NEW_CARD_BG,
  COLOR_NEW_WHITE,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
    },

    title: {
      textAlign: 'center',
      color: COLOR_NEW_WHITE,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(3.25),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    links: {
      ...flexHelper('center', 'center'),
      flexWrap: 'wrap',
      paddingBottom: theme.spacing(11),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(6.25),
      },
      margin: theme.spacing(-6.5),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(-2),
      },
    },
    linkWrapper: {
      margin: theme.spacing(3.25),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1),
      },
    },
    link: {
      width: 72,
      height: 72,
      borderRadius: '10px',
      border: `1px solid ${COLOR_NEW_BORDER}`,
      background: COLOR_NEW_CARD_BG,
      ...flexHelper('center', 'center'),
      '&:hover': {
        background: COLOR_NEW_BG,
      },
    },
  });
});
