import userActionTypes from 'store/user/actionTypes';
import mintActionTypes from 'store/mint/actionTypes';
import claimActionTypes from 'store/claims/actionTypes';
import pricesActionTypes from 'store/prices/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';
import { getUIReducer } from '.';

const initialState: UIState = {
  [userActionTypes.LOGIN]: RequestStatus.INIT,
  [mintActionTypes.BUY]: RequestStatus.INIT,
  [mintActionTypes.GET_AVAILABLE_LEFT]: RequestStatus.INIT,
  [claimActionTypes.CLAIM]: RequestStatus.INIT,
  [claimActionTypes.GET_USER_CLAIMS]: RequestStatus.INIT,
  [pricesActionTypes.GET_PRICES_FOR_ONE_NFT]: RequestStatus.INIT,
  [pricesActionTypes.GET_USDT_PRICE]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
