import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_NEW_ACCENT, COLOR_NEW_ACCENT_BG } from '../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      width: 266,
      padding: theme.spacing(2, 2.5, 4, 3),
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    title: {
      '& h3': {
        fontSize: 18,
        lineHeight: '30px',
      },
    },
    btn: {
      color: COLOR_NEW_ACCENT,
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    selected: {
      backgroundColor: COLOR_NEW_ACCENT_BG,
      borderColor: COLOR_NEW_ACCENT,
    },
  });
});
