import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { Modals } from 'types';
import actionTypes from '../actionTypes';
import { showNftsMetadata } from '../actions';
import { setActiveModal } from '../reducer';

export function* showNftsMetadataSaga({
  type,
  payload: { contractAddress, tokensId, txHash },
}: ReturnType<typeof showNftsMetadata>) {
  yield put(apiActions.request(type));
  try {
    const { data } = yield call(baseApi.getTokensMetadata, { contractAddress, tokensId });
    const images = data.map((tokenInfo) => tokenInfo.image);

    yield put(setActiveModal({
      open: true, nftsImages: images, activeModal: Modals.MeetNfts, txHash,
    }));

    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SHOW_NFT_METADATA, showNftsMetadata);
}
