import React, { VFC } from 'react';
import { Modal, ModalProps } from 'components/Modal';
import { SwiperImg } from 'components/SwiperImg';
import { useStyles } from './MeetYourNftsModal.styles';

export interface MeetYourNftsModalProps extends ModalProps {
  images: string[];
  txHash: string;
}

export const MeetYourNftsModal: VFC<MeetYourNftsModalProps> = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    images,
    txHash,
  } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      customTitle="Here we go! Meet your NFTs!"
      titleClassName={classes.title}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      paperClassName={classes.paper}
    >
      <SwiperImg txHash={txHash} images={images} />
    </Modal>
  );
};
