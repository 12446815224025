import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
  },
  container: {
    '&>div': {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(5.75),
      },
      [theme.breakpoints.down('md')]: {
        '&:first-of-type': {
          marginTop: theme.spacing(20),
        },
      },
    },
  },
}));
//
