import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const DiscordLogo: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path d="M10.3881 10.5679C9.70406 10.5679 9.16406 11.1679 9.16406 11.8999C9.16406 12.6319 9.71606 13.2319 10.3881 13.2319C11.0721 13.2319 11.6121 12.6319 11.6121 11.8999C11.6241 11.1679 11.0721 10.5679 10.3881 10.5679ZM14.7681 10.5679C14.0841 10.5679 13.5441 11.1679 13.5441 11.8999C13.5441 12.6319 14.0961 13.2319 14.7681 13.2319C15.4521 13.2319 15.9921 12.6319 15.9921 11.8999C15.9921 11.1679 15.4521 10.5679 14.7681 10.5679Z" fill="#FACC14" />
    <path d="M20.6001 0.5H4.52006C3.16406 0.5 2.06006 1.604 2.06006 2.972V19.196C2.06006 20.564 3.16406 21.668 4.52006 21.668H18.1281L17.4921 19.448L19.0281 20.876L20.4801 22.22L23.0601 24.5V2.972C23.0601 1.604 21.9561 0.5 20.6001 0.5ZM15.9681 16.172C15.9681 16.172 15.5361 15.656 15.1761 15.2C16.7481 14.756 17.3481 13.772 17.3481 13.772C16.8561 14.096 16.3881 14.324 15.9681 14.48C15.3681 14.732 14.7921 14.9 14.2281 14.996C13.0761 15.212 12.0201 15.152 11.1201 14.984C10.4361 14.852 9.84806 14.66 9.35606 14.468C9.08006 14.36 8.78006 14.228 8.48006 14.06C8.44406 14.036 8.40806 14.024 8.37206 14C8.34806 13.988 8.33606 13.976 8.32406 13.964C8.10806 13.844 7.98806 13.76 7.98806 13.76C7.98806 13.76 8.56406 14.72 10.0881 15.176C9.72806 15.632 9.28406 16.172 9.28406 16.172C6.63206 16.088 5.62406 14.348 5.62406 14.348C5.62406 10.484 7.35206 7.352 7.35206 7.352C9.08006 6.056 10.7241 6.092 10.7241 6.092L10.8441 6.236C8.68406 6.86 7.68806 7.808 7.68806 7.808C7.68806 7.808 7.95206 7.664 8.39606 7.46C9.68006 6.896 10.7001 6.74 11.1201 6.704C11.1921 6.692 11.2521 6.68 11.3241 6.68C12.0561 6.584 12.8841 6.56 13.7481 6.656C14.8881 6.788 16.1121 7.124 17.3601 7.808C17.3601 7.808 16.4121 6.908 14.3721 6.284L14.5401 6.092C14.5401 6.092 16.1841 6.056 17.9121 7.352C17.9121 7.352 19.6401 10.484 19.6401 14.348C19.6401 14.348 18.6201 16.088 15.9681 16.172Z" fill="#FACC14" />
  </BaseSVGIcon>
);
