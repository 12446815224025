import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './UseAndEarn.styles';
import { useAndEarnContent } from './content';
import { UseAndEarnItem } from '../UseAndEarnItem';

export interface UseAndEarnProps {
  className?: string;
}

export const UseAndEarn: VFC<UseAndEarnProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)}>
      <Typography variant="h1" className={clsx(classes.title)}>
        {t('How to Use and Earn NFTs')}
      </Typography>
      <Box className={classes.infoContainer}>
        {useAndEarnContent.map((item) => (
          <UseAndEarnItem
            key={item.title}
            Icon={item.Icon}
            title={item.title}
            description={item.description}
            descriptionClassName={classes[item.className]}
          />
        ))}
      </Box>
    </Box>
  );
};
