import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_NEW_WHITE } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    title: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    description: {
      maxWidth: 804,
      margin: '82px auto 0',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(5),
      },
    },
    descriptionHighlighted: {
      color: COLOR_NEW_WHITE,
    },
  });
});
