import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MintState } from 'types';

const initialState: MintState = {
  availableLeft: 0,
};

export const mintReducer = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    updateMintState: (state, action: PayloadAction<Partial<MintState>>) => ({
      ...state, ...action.payload,
    }),
    clearMintState: () => ({
      ...initialState,
    })
    ,
  },
});

export const {
  updateMintState,
  clearMintState,
} = mintReducer.actions;

export default mintReducer.reducer;
