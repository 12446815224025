import React, {
  useCallback, useEffect, useState, VFC,
} from 'react';

import {
  Box, Button, IconButton, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { CloseIcon, MenuIcon, WalletIcon } from 'theme/icons';

import { routes } from 'appConstants';
import { HashLink } from 'react-router-hash-link';
import { DisconnectModal } from 'components/DisconnectModal';
import {
  Languages, State, UserState, WalletProviders,
} from 'types';
import { ConnectWalletModal } from 'components/ConnectWalletModal';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { Logo } from 'components';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MobileNavigation.styles';

export interface MobileNavigationProps {
  className?: string;
  disconnect: () => void;
  onConnectWallet: (wallet: WalletProviders) => Promise<void>;
}

export const MobileNavigation: VFC<MobileNavigationProps> = ({
  className,
  disconnect,
  onConnectWallet,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>(Languages.en);

  const {
    address,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const disconnectWallet = useCallback(() => {
    disconnect();
    setIsDisconnectModalOpen(false);
  }, []);
  const connectWallet = useCallback((wallet: WalletProviders) => {
    onConnectWallet(wallet);
    setIsConnectModalOpen(false);
  }, []);
  const toggleDisconnectModal = useCallback(() => {
    setIsDisconnectModalOpen(!isDisconnectModalOpen);
  }, [isDisconnectModalOpen]);

  const toggleConnectModal = useCallback(() => {
    setIsConnectModalOpen(!isConnectModalOpen);
  }, [isConnectModalOpen]);

  const toggleModal = useCallback(() => {
    if(address) {
      toggleDisconnectModal();
    }else{
      toggleConnectModal();
    }
  }, [address, toggleDisconnectModal, toggleConnectModal]);
  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleLanguageSelect = (lang:Languages) => {
    navigate(`/${lang}`);
  };

  useEffect(() => {
    let lang = location.pathname.replace('/', '');
    if(!lang) {
      const lsLang = localStorage.getItem('i18nextLng');
      if (!lsLang || !(lsLang === 'kr' || lsLang === 'en')) {
        lang = Languages.en;
      }else{
        lang = localStorage.getItem('i18nextLng');
      }
    }
    setSelectedLanguage(lang as Languages);
  }, [location.pathname]);

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.head}>
        <IconButton className={clsx(classes.menuBtn, classes.btn)} onClick={toggleMenu}>
          <MenuIcon />
        </IconButton>
        <Box className={classes.left}>
          <Logo className={classes.logo} />
        </Box>
        <IconButton className={clsx(classes.walletBtn, classes.btn)} onClick={toggleModal}>
          <WalletIcon />
        </IconButton>
      </Box>
      {/* opened menu */}
      <Box className={clsx(clsx(classes.main, isOpen && classes.open))}>
        <Typography variant="h2" className="accent center">
          Menu
        </Typography>
        <nav className={classes.navigation}>
          <ul className={classes.navigationList}>
            <li>
              <HashLink
                smooth
                to={routes.about.root}
                className={classes.navigationItem}
                onClick={toggleMenu}
              >
                <Typography className="text uppercase">
                  {t('About our NFTs')}
                </Typography>
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to={routes.benefits.root}
                className={classes.navigationItem}
                onClick={toggleMenu}
              >
                <Typography className="text uppercase">
                  {t('Benefits for holders')}
                </Typography>
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to={routes.follow.root}
                className={classes.navigationItem}
                onClick={toggleMenu}
              >
                <Typography className="text uppercase">{t('Follow us')}</Typography>
              </HashLink>
            </li>
          </ul>
        </nav>
        <Button
          onClick={() => handleLanguageSelect(Languages.en)}
          className={clsx({ [classes.selected]: selectedLanguage === Languages.en },
            classes.langBtn)}
        >
          English
        </Button>
        <Button
          onClick={() => handleLanguageSelect(Languages.kr)}
          className={clsx({ [classes.selected]: selectedLanguage === Languages.kr },
            classes.langBtn)}
        >
          한국어
        </Button>
        <IconButton className={clsx(classes.closeBtn, classes.btn)} onClick={toggleMenu}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DisconnectModal
        open={isDisconnectModalOpen}
        disconnectWallet={disconnectWallet}
        onClose={toggleDisconnectModal}
      />
      <ConnectWalletModal
        open={isConnectModalOpen}
        connectWallet={connectWallet}
        onClose={toggleConnectModal}
      />
    </Box>
  );
};
