import { contractsConfig, ContractsNames } from 'config';
import { Chains } from 'types';
import { profileGif, seedsGif, scythesGif } from 'assets/images';

export const claimsInfo = {
  [contractsConfig.contracts[ContractsNames.profile].address[
    Chains.bsc
  ].toUpperCase()]: {
    images: profileGif,
    badge: 'Profile',
    type: 'profile',
  },
  [contractsConfig.contracts[ContractsNames.vegetables].address[
    Chains.bsc
  ].toUpperCase()]: {
    images: seedsGif,
    badge: '',
    type: 'seeds',
  },
  [contractsConfig.contracts[ContractsNames.scythes].address[
    Chains.bsc
  ].toUpperCase()]: {
    images: scythesGif,
    badge: 'Farming tool',
    type: 'scythes',
  },
};
