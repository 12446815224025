import React, { useState, VFC } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { MinusIcon, PlusIcon } from 'theme/icons';
import { useStyles } from './Counter.styles';

export interface CounterProps {
  className?: string;
  initialValue?: number;
  onChange?: (value: number) => void;
  max?: number;
}

export const Counter: VFC<CounterProps> = ({
  className,
  initialValue = 1,
  onChange,
  max,
}) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(initialValue);

  const handleDecrement = () => {
    setCounter((prevState) => {
      const newState = prevState - 1;
      if (onChange) {
        onChange(newState);
      }
      return newState;
    });
  };

  const handleIncrement = () => {
    setCounter((prevState) => {
      const newState = prevState + 1;
      if (onChange) {
        onChange(newState);
      }
      return newState;
    });
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <IconButton
        className={classes.btn}
        onClick={handleDecrement}
        disabled={counter === 1}
      >
        <MinusIcon />
      </IconButton>
      <Typography className={clsx(classes.counterText, 'xl')}>
        {counter < 10 ? `0${counter}` : counter}
      </Typography>
      <IconButton
        className={classes.btn}
        onClick={handleIncrement}
        disabled={counter === 40 || counter >= max}
      >
        <PlusIcon />
      </IconButton>
    </Box>
  );
};
