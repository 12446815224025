import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper(),
    flexDirection: 'column',
    gap: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(6.25),
    },
  },
  section: {
    width: '100%',
  },
}));
