import { call, put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { erc721Abi } from 'config/abi';
import { wBnb } from 'config/constants';
import { contractsConfig } from 'config';
import { getTokenAmountDisplay } from 'utils';
import { clearPricesState, updatePricesState } from '../reducer';
import { getPricesForOneNft } from '../actions';
import actionTypes from '../actionTypes';
import { getUsdtPriceSaga } from './getUsdtPrice';

export function* getPriceForOneSaga({
  type,
  payload: { isProfile, web3Provider },
}: ReturnType<typeof getPricesForOneNft>) {
  yield put(apiActions.request(type));
  yield put(clearPricesState());
  try {
    const address = isProfile ?
      contractsConfig.contracts.profile.address['Binance-Smart-Chain'] : contractsConfig.contracts.scythes.address['Binance-Smart-Chain'];

    const tokenContract = yield new web3Provider.eth.Contract(
      erc721Abi,
      address,
    );
    // 0 - always native token
    const priceForOneNft = yield call(
      tokenContract.methods.pricePerSingle(0).call,
    );
    const formattedPriceForOneNft = getTokenAmountDisplay(
      priceForOneNft,
    );
    const usdtAmount = yield call(getUsdtPriceSaga, {
      type: actionTypes.GET_USDT_PRICE,
      payload: {
        tokenAddress: wBnb,
        amountIn: priceForOneNft,
        web3Provider,
      },
    });
    yield put(
      updatePricesState({
        price: formattedPriceForOneNft,
        usdPrice: usdtAmount,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    yield put(clearPricesState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_PRICES_FOR_ONE_NFT, getPriceForOneSaga);
}
