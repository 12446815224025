import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from '../../utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
      maxWidth: 880,
      margin: '0 auto',
    },
    title: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    infoContainer: {
      ...flexHelper(),
      marginTop: theme.spacing(8),
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    trade: {
      maxWidth: 700,
    },
    box: {
      maxWidth: 680,
    },
  });
});
