import { LinkProps } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import {
  COLOR_NEW_ACCENT,
  COLOR_NEW_BORDER_2, COLOR_NEW_ACCENT_100, COLOR_NEW_DARK_BLUE, COLOR_NEW_HEAD_TEXT,
} from 'theme/colors';

export const getMuiIconButton = (): Overrides['MuiIconButton'] => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_NEW_BORDER_2,
          stroke: COLOR_NEW_BORDER_2,
        },
      },
    },

    '&:hover': {
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_NEW_ACCENT,
            stroke: COLOR_NEW_ACCENT,
          },
        },
      },
      backgroundColor: 'transparent',
    },
    '&:active': {
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_NEW_ACCENT_100,
            stroke: COLOR_NEW_ACCENT_100,
          },
        },
      },
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      color: COLOR_NEW_ACCENT,
      backgroundColor: 'transparent',
    },
  },

  colorSecondary: {
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_NEW_DARK_BLUE,
          stroke: COLOR_NEW_DARK_BLUE,
        },
      },
    },
    backgroundColor: COLOR_NEW_HEAD_TEXT,

    '&:hover': {
      backgroundColor: COLOR_NEW_ACCENT,
    },
    '&:active': {
      background: COLOR_NEW_ACCENT_100,
    },
  },
});

export const getMuiIconButtonDefaultProps = (): LinkProps => ({});
