import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PricesState } from 'types';

const initialState: PricesState = {
  price: '0',
  usdPrice: '0',
};

export const pricesReducer = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    updatePricesState: (state, action: PayloadAction<Partial<PricesState>>) => ({
      ...state, ...action.payload,
    }),
    clearPricesState: () => ({
      ...initialState,
    })
    ,
  },
});

export const {
  updatePricesState,
  clearPricesState,
} = pricesReducer.actions;

export default pricesReducer.reducer;
