import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_NEW_BLACK_100, COLOR_NEW_BORDER_2, COLOR_NEW_CARD_BG } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    borderRadius: theme.spacing(2),
    width: '340px',
    height: '450px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      display: 'block',
    },
  },
  profile: {
  },
  scythes: {
    padding: theme.spacing(3.5, 2.5),
    backgroundColor: COLOR_NEW_CARD_BG,
  },
  badge: {
    position: 'absolute',
    padding: theme.spacing(1),
    top: theme.spacing(3),
    left: theme.spacing(2),
    backgroundColor: COLOR_NEW_BLACK_100,
    borderRadius: '107px',
    border: `1px solid ${COLOR_NEW_BORDER_2}`,
    '& p': {
      margin: 0,
    },
  },
}));
