export const validStatuses = [
  200,
  201,
];

export const errorStatuses = [
  400,
  401,
  403,
  409,
  500,
  503,
];
