export const PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const isMainnet = true;
export const nativeToken = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const wBnb = isMainnet
  ? '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  : '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd';
export const usdt = isMainnet
  ? '0x55d398326f99059ff775485246999027b3197955'
  : '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684';

export const explorerUrl = isMainnet ? 'https://www.bscscan.com/tx/' : 'https://testnet.bscscan.com/tx/';
