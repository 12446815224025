import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { erc1155Abi, erc721Abi } from 'config/abi';
import { contractsConfig } from 'config';
import { nativeToken } from 'config/constants';
import userSelector from 'store/user/selectors';
import pricesSelector from 'store/prices/selectors';
import { baseApi } from 'store/api/apiRequestBuilder';
import { getTokenAmount } from 'utils';
import BigNumber from 'bignumber.js/bignumber';
import { toast } from 'react-toastify';
import { ReactText } from 'react';
import { showNftsMetadataSaga } from 'store/modals/sagas/showNftsMetadata';
import actionTypesModals from 'store/modals/actionTypes';
import { clearMintState } from '../reducer';
import { buy } from '../actions';
import actionTypes from '../actionTypes';
import { getAvailableLeftSaga } from './getAvailableLeft';

export function* buySaga({
  type,
  payload: { web3Provider, isProfile, amount },
}: ReturnType<typeof buy>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const payableAmount = yield select(pricesSelector.getProp('price'));
  let toastId: ReactText;
  try {
    const address = isProfile
      ? contractsConfig.contracts.profile.address['Binance-Smart-Chain']
      : contractsConfig.contracts.scythes.address['Binance-Smart-Chain'];

    const tokenContract = isProfile
      ? yield new web3Provider.eth.Contract(erc721Abi, address)
      : yield new web3Provider.eth.Contract(erc1155Abi, address);

    const token = nativeToken;

    toastId = toast.loading('Minting...');

    const { data: ids } = yield call(baseApi.getRandomTokenIds, {
      address,
      count: amount,
    });
    const formattedPayableAmount = new BigNumber(getTokenAmount(payableAmount))
      .multipliedBy(amount)
      .toString();
    let tx;
    if (isProfile) {
      tx = yield call(tokenContract.methods.buy(token, ids).send, {
        value: formattedPayableAmount,
        from: myAddress,
      });
    } else {
      const sortedIds = ids.sort();
      const uniqueIds = [...new Set(sortedIds)];
      const amounts = uniqueIds.map(
        (item) => sortedIds.filter((i) => i === item).length,
      );
      tx = yield call(tokenContract.methods.buy(token, uniqueIds, amounts).send, {
        value: formattedPayableAmount,
        from: myAddress,
      });
    }

    yield call(showNftsMetadataSaga, {
      type: actionTypesModals.SHOW_NFT_METADATA,
      payload: {
        contractAddress: address,
        tokensId: ids,
        txHash: tx.transactionHash,
      },
    });
    toast.update(toastId, {
      render: 'Success',
      isLoading: false,
      type: 'success',
      closeOnClick: true,
    });
    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    toast.update(toastId, {
      render: 'Something went wrong',
      isLoading: false,
      type: 'error',
      closeOnClick: true,
    });
    yield put(apiActions.error(type, err));
    yield put(clearMintState());
  }finally {
    yield call(getAvailableLeftSaga, {
      type: actionTypes.GET_AVAILABLE_LEFT,
      payload: {
        isProfile,
        web3Provider,
      },
    });
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BUY, buySaga);
}
