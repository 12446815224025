import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';
import { COLOR_NEW_BLACK_100, COLOR_NEW_CARD_BG_GRAY } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      position: 'relative',
      // boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.25)',
      border: `1px solid ${COLOR_NEW_BLACK_100}`,
      background: `${COLOR_NEW_CARD_BG_GRAY}`,
      borderRadius: 14,
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    modalTitle: {
      ...flexHelper('space-between'),
      marginBottom: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
    title: {
      // margin: '0 auto',
      width: '100%',
      // textAlign: 'center',
    },
    sm: {
      padding: theme.spacing(3, 4),
    },
    md: {
      padding: theme.spacing(2, 4, 3, 4),
    },
    lg: {
      padding: theme.spacing(5),
    },
    disconnect: {
      padding: theme.spacing(3, 8),
    },
  });
});
