import React, { useRef, useState, VFC } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Swiper as SwiperType } from 'swiper';
import { Copyable } from 'components';
import { explorerUrl } from 'config/constants';
import { useStyles } from './SwiperImg.styles';

import 'swiper/swiper.min.css';

export interface SwiperImgProps {
  className?: string;
  txHash: string;
  images: string[];
}
export const SwiperImg: VFC<SwiperImgProps> = ({
  className,
  txHash,
  images,
}) => {
  const classes = useStyles();
  const navigationNextRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  return (
    <Box className={clsx(classes.root, className)}>
      <Swiper
        slidesPerView={images.length < 3 ? images.length : 3}
        spaceBetween={30}
        centeredSlides
        allowTouchMove={false}
        loop
        initialSlide={0}
        pagination={{
          clickable: true,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: images.length < 2 ? images.length : 2,
          },
          1000: {
            slidesPerView: images.length < 3 ? images.length : 3,
          },
        }}
        modules={[Navigation]}
        className={classes.containerImg}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
      >
        {images.map((img) => (
          <SwiperSlide key={img} className={classes.slide}>
            {({ isActive }) => (
              <img
                src={img}
                alt="claim"
                className={clsx(classes.img, isActive ? classes.active : '')}
              />
            )}
          </SwiperSlide>
        ))}
        <Box className={clsx(classes.containerBtn, className)}>
          <Copyable
            valueToCopy={`${explorerUrl}${txHash}`}
            withIcon
            onlyIconActive
            transparent
          >
            <a
              href={`${explorerUrl}${txHash}`}
              target="_blank"
              className={clsx(classes.txHashLink)}
              rel="noreferrer"
            >
              <Typography noWrap className={clsx(classes.txHash)}>
                {explorerUrl}
                {txHash}
              </Typography>
            </a>
          </Copyable>
          {images.length > 1 && (
            <Button
              variant="contained"
              onClick={() => swiperInstance.slideNext()}
            >
              Next
            </Button>
          )}
        </Box>
      </Swiper>
    </Box>
  );
};
