import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const MinusIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.1001 9.42631H4.40993C4.02786 9.42631 3.71777 9.11691 3.71777 8.73415C3.71777 8.35139 4.02786 8.04199 4.40993 8.04199H14.1001C14.4829 8.04199 14.7923 8.35139 14.7923 8.73415C14.7923 9.11691 14.4829 9.42631 14.1001 9.42631Z" fill="#262626" />
    <mask id="mask0_530_638" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="8" width="12" height="2">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1001 9.42631H4.40993C4.02786 9.42631 3.71777 9.11691 3.71777 8.73415C3.71777 8.35139 4.02786 8.04199 4.40993 8.04199H14.1001C14.4829 8.04199 14.7923 8.35139 14.7923 8.73415C14.7923 9.11691 14.4829 9.42631 14.1001 9.42631Z" fill="white" />
    </mask>
    <g mask="url(#mask0_530_638)" />
  </BaseSVGIcon>
);
