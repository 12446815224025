import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { flexHelper } from 'utils';
import { COLOR_NEW_ACCENT, COLOR_NEW_BORDER, COLOR_NEW_CARD_BG } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    paddingBottom: theme.spacing(6.25),
    paddingTop: '55px',
    ...flexHelper('space-between', 'center'),
    flexDirection: 'column',
    gap: '22px',
    backgroundColor: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER}`,
    margin: '0 2px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '55px 22px 34px',
    },
  },
  logo: {
    maxWidth: '135px',
  },
  about: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '270px',
      textAlign: 'center',
    },
  },
  webSiteLink: {
    color: COLOR_NEW_ACCENT,
  },
}));
