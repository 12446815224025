import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { CSSProperties } from '@material-ui/styles/withStyles';
import {
  COLOR_NEW_BLACK,
  COLOR_NEW_BORDER_2, COLOR_NEW_HEAD_TEXT, COLOR_NEW_ACCENT, COLOR_NEW_ACCENT_100,
} from 'theme/colors';
import { FontWeights } from 'theme/Typography/Typography.theme';

export const getMuiButton = (theme: Theme): Overrides['MuiButton'] => {
  const outlinedCommon: CSSProperties = {
    textAlign: 'center',
    borderRadius: '10px',
    border: `1px solid ${COLOR_NEW_BORDER_2}`,
    transition: '200ms',
    color: COLOR_NEW_HEAD_TEXT,
    textTransform: 'uppercase',
    fontWeight: FontWeights.fontWeightMedium,
    padding: theme.spacing(1, 6),
    fontSize: '13px',
    lineHeight: '24px',

    '& $endIcon': {
      '& > *': {
        '& > *': {
          fill: COLOR_NEW_HEAD_TEXT,
        },
      },
    },

    '&$sizeLarge': {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '24px',
      padding: theme.spacing(1, 2.5),
      borderRadius: '38px',
      letterSpacing: '0.01em',
    },
    '&$sizeSmall': {
      height: '24px',
      padding: theme.spacing(0.25, 2.5),
    },

    '&:hover': {
      // color: COLOR_NEW_BLACK,
      background: 'transparent',
      borderColor: COLOR_NEW_ACCENT,
      '& $endIcon': {
        '& > *': {
          '& > *': {
            fill: COLOR_NEW_ACCENT,
          },
        },
      },
    },
    '&$disabled': {
      opacity: 0.5,
    },
  };

  return {
    root: {
      minWidth: 0,
    },
    outlined: {
      ...outlinedCommon,
    },
    contained: {
      ...outlinedCommon,
      backgroundColor: COLOR_NEW_ACCENT,
      borderColor: COLOR_NEW_ACCENT,
      color: COLOR_NEW_BLACK,
      '&:hover': {
        backgroundColor: COLOR_NEW_ACCENT_100,
        borderColor: COLOR_NEW_ACCENT_100,
      },
    },
    containedSecondary: {
      ...outlinedCommon,
      color: COLOR_NEW_BLACK,
      backgroundColor: COLOR_NEW_ACCENT_100,
      '&:hover': {
        backgroundColor: COLOR_NEW_ACCENT,
        borderColor: COLOR_NEW_ACCENT,
        color: COLOR_NEW_BLACK,
      },
    },
    text: {
      background: 'none',
      backgroundColor: 'transparent',
      transition: '0',
      boxSizing: 'border-box',
      borderColor: 'transparent',
      padding: 0,
      margin: 0,
      minWidth: 0,
      '&:hover': {
        filter: 'brightness(85%)',
        backgroundColor: 'transparent',
      },
      '&.clear:hover': {
        filter: 'brightness(100%)',
      },
      '&.accent': {
        color: COLOR_NEW_ACCENT,
      },
    },

    startIcon: {
      marginRight: theme.spacing(1.5),
      marginLeft: 0,
    },
    endIcon: {
      marginLeft: theme.spacing(1.5),
      marginRight: 0,
    },
  };
};

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'outlined',
});
