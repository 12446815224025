import React, {
  useCallback, useEffect, useState, VFC,
} from 'react';
import {
  Box, Button, Container, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Copyable, Logo } from 'components';

import {
  Languages, State, UserState, WalletProviders,
} from 'types';
import { DisconnectModal } from 'components/DisconnectModal';
import { HashLink } from 'react-router-hash-link';
import { routes } from 'appConstants';
import { ConnectWalletModal } from 'components/ConnectWalletModal';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import { ChangeLanguageModal } from 'components/ChangeLanguageModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useStyles } from './Header.styles';

export interface HeaderProps {
  disconnect: () => void;
  onConnectWallet: (wallet: WalletProviders) => Promise<void>;
  className?: string;
}

export const Header: VFC<HeaderProps> = ({
  className,
  disconnect,
  onConnectWallet,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    address,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isChangeLanguageModalOpen, setIsChangeLanguageModalOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState<Languages>(Languages.en);

  const toggleDisconnectModal = () => {
    setIsDisconnectModalOpen((prevState) => !prevState);
  };

  const toggleConnectModal = () => {
    setIsConnectModalOpen((prevState) => !prevState);
  };
  const toggleChangeLanguageModal = () => {
    setIsChangeLanguageModalOpen((prevState) => !prevState);
  };

  const disconnectWallet = useCallback(() => {
    disconnect();
    setIsDisconnectModalOpen(false);
  }, []);

  const connectWallet = useCallback((wallet: WalletProviders) => {
    onConnectWallet(wallet);
    setIsConnectModalOpen(false);
  }, []);
  const handleLanguageSelect = (lang:Languages) => {
    navigate(`/${lang}`);
  };
  // const handleLanguageChange = useCallback((language:Languages) => {
  //   i18n.changeLanguage(language).then(() => {
  //     setSelectedLanguage(language);
  //   });
  // }, []);

  useEffect(() => {
    let lang = location.pathname.replace('/', '');
    if(!lang) {
      const lsLang = localStorage.getItem('i18nextLng');
      if (!lsLang || !(lsLang === 'kr' || lsLang === 'en')) {
        lang = Languages.en;
      }else{
        lang = localStorage.getItem('i18nextLng');
      }
    }
    setSelectedLanguage(lang as Languages);
  }, [location.pathname]);

  return (
    <>
      <Box className={clsx(classes.headerWrap, className)}>
        <Container className={clsx(classes.root)}>
          <Box className={classes.header}>
            <Box className={classes.left}>
              <Logo className={classes.logo} />
            </Box>
            <nav>
              <ul className={classes.navigationList}>
                <li>
                  <HashLink
                    smooth
                    to={routes.about.root}
                    className={classes.navigationItem}
                  >
                    <Typography className="text">{t('About our NFTs')}</Typography>
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    smooth
                    to={routes.benefits.root}
                    className={classes.navigationItem}
                  >
                    <Typography className="text">{t('Benefits for holders')}</Typography>
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    smooth
                    to={routes.follow.root}
                    className={classes.navigationItem}
                  >
                    <Typography className="text">{t('Follow us')}</Typography>
                  </HashLink>
                </li>
              </ul>
            </nav>
            <Box className={classes.btns}>

              {address ? (
                <>
                  <Copyable
                    className={clsx(classes.copyable, classes.right)}
                    onlyIconActive
                    withBorder
                    withIcon
                    valueToCopy={address}
                  >
                    <Button variant="text" onClick={toggleDisconnectModal}>
                      <Typography noWrap variant="button">
                        {address}
                      </Typography>
                    </Button>
                  </Copyable>
                </>
              ) : (
                <Button
                  onClick={toggleConnectModal}
                  className={clsx(classes.headerButton, classes.right)}
                  variant="outlined"
                  size="large"
                >
                  Connect wallet
                </Button>
              )}
              <Button
                onClick={toggleChangeLanguageModal}
                className={classes.languageButton}
                variant="outlined"
              >
                {selectedLanguage}
              </Button>
            </Box>
          </Box>
          <DisconnectModal
            open={isDisconnectModalOpen}
            disconnectWallet={disconnectWallet}
            onClose={toggleDisconnectModal}
          />
          <ConnectWalletModal
            open={isConnectModalOpen}
            connectWallet={connectWallet}
            onClose={toggleConnectModal}
          />
          <ChangeLanguageModal
            changeLanguage={handleLanguageSelect}
            selectedLanguage={selectedLanguage}
            open={isChangeLanguageModalOpen}
            onClose={toggleChangeLanguageModal}
          />
        </Container>
      </Box>
    </>
  );
};
