import React, {
  useCallback, useMemo, useState, VFC,
} from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useShallowSelector } from 'hooks';
import claimsSelector from 'store/claims/selectors';
import userSelector from 'store/user/selectors';
import uiSelector from 'store/ui/selectors';
import claimsActionTypes from 'store/claims/actionTypes';
import mintActionTypes from 'store/mint/actionTypes';
import { IClaimFromBackend, RequestStatus, State } from 'types';
import { useDispatch } from 'react-redux';
import { claim } from 'store/claims/actions';
import { useWalletConnectorContext } from 'services';
import { useStyles } from './NftActionsSection.styles';
import { LabeledSwitch } from '../LabeledSwitch';
import { ClaimSection } from '../ClaimSection';
import { MintSection } from '../MintSection';

export interface NftActionsSectionProps {
  className?: string;
}

export const NftActionsSection: VFC<NftActionsSectionProps> = ({
  className,
}) => {
  const classes = useStyles();
  const [isClaim, setIsClaim] = useState(false);

  const claims = useShallowSelector<State, Array<IClaimFromBackend>>(
    claimsSelector.getProp('claims'),
  );
  const userAddress = useShallowSelector<State, string>(
    userSelector.getProp('address'),
  );

  const {
    [claimsActionTypes.CLAIM]: claimRequestStatus,
    [mintActionTypes.GET_AVAILABLE_LEFT]: mintInfoRequesStatus,
  } = useShallowSelector(uiSelector.getUI);

  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const isClaimAvailable = useMemo(() => claims.length > 0, [claims]);
  const isClaiming = useMemo(
    () => claimRequestStatus === RequestStatus.REQUEST,
    [claimRequestStatus],
  );

  const isLoadingMintInfo = useMemo(
    () => mintInfoRequesStatus === RequestStatus.REQUEST,
    [mintInfoRequesStatus],
  );

  const handleSwitchChange = () => {
    setIsClaim((prevState) => !prevState);
  };
  const handleClaimNft = useCallback((contractAddress: string) => {
    dispatch(claim({ contractAddress, web3Provider: walletService, userAddress }));
  }, [userAddress, walletService]);

  return (
    <Box className={clsx(classes.root, className)}>
      {isClaimAvailable && (
        <LabeledSwitch
          firstLabel="Buy NFT"
          secondLabel="Claim NFT"
          isSecondaryVariant
          onChange={handleSwitchChange}
        />
      )}
      {!isClaimAvailable || !isClaim ? (
        <MintSection isLoadintMintInfo={isLoadingMintInfo} className={classes.section} />
      ) : (
        isClaim && (
          <ClaimSection
            availableClaimsAddresses={claims.map((claimItem) => claimItem.address.toUpperCase())}
            className={classes.section}
            isButtonDisabled={isClaiming || !userAddress}
            handleClaimNft={handleClaimNft}
          />
        )
      )}
    </Box>
  );
};
