import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { BreakpointsProvider } from 'hooks';
import { theme } from 'theme';
import { Layout } from 'containers';
import { ToastContainer } from 'react-toastify';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-toastify/dist/ReactToastify.css';
import { Home } from 'pages/Home';
import { COLOR_NEW_ACCENT, COLOR_NEW_CARD_BG } from 'theme/colors';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SkeletonTheme baseColor={COLOR_NEW_CARD_BG} highlightColor={COLOR_NEW_ACCENT}>
        <BreakpointsProvider>
          <StylesProvider>
            <CssBaseline />
            <ToastContainer
              autoClose={4000}
              hideProgressBar
              position="top-right"
              closeButton={false}
            />
            <Layout>
              <Home />
            </Layout>
          </StylesProvider>
        </BreakpointsProvider>
      </SkeletonTheme>
    </ThemeProvider>
  );
}

export default App;
