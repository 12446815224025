import React, {
  useCallback, useEffect, useMemo, useState, VFC,
} from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { getPricesForOneNft } from 'store/prices/actions';
import { buy } from 'store/mint/actions';
import { useShallowSelector } from 'hooks';
import {
  PricesState, RequestStatus, State, UserState,
} from 'types';
import pricesSelector from 'store/prices/selectors';
import { useWalletConnectorContext } from 'services';
import BigNumber from 'bignumber.js/bignumber';
import userSelector from 'store/user/selectors';
import actionTypes from 'store/mint/actionTypes';
import pricesActionTypes from 'store/prices/actionTypes';
import uiSelector from 'store/ui/selectors';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useStyles } from './PriceBox.styles';
import { Counter } from '../Counter';

export interface PriceBoxProps {
  className?: string;
  available: number;
  isProfile?:boolean;
}

export const PriceBox: VFC<PriceBoxProps> = ({
  className,
  available,
  isProfile = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { walletService } = useWalletConnectorContext();
  const { price, usdPrice } = useShallowSelector<State, PricesState>(
    pricesSelector.getPrices,
  );
  const {
    address,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const {
    [actionTypes.BUY]: mintRequestStatus,
    [pricesActionTypes.GET_PRICES_FOR_ONE_NFT]: getPriceForNftRequestStatus,
    [pricesActionTypes.GET_USDT_PRICE]: getUsdtPriceRequesStatus,
  } = useShallowSelector(uiSelector.getUI);

  const [amount, setAmount] = useState(1);

  const buyTokens = useCallback(() => {
    dispatch(buy({ web3Provider: walletService, isProfile, amount }));
  }, [dispatch, amount, isProfile, walletService]);

  const handleCounterChange = useCallback((value:number) => {
    setAmount(value);
  }, []);

  const isBuyLoading = useMemo(
    () => mintRequestStatus === RequestStatus.REQUEST,
    [mintRequestStatus],
  );

  const isInfoForMintLoading = useMemo(
    () => [getPriceForNftRequestStatus, getUsdtPriceRequesStatus].includes(RequestStatus.REQUEST),
    [getUsdtPriceRequesStatus, getPriceForNftRequestStatus],
  );

  useEffect(() => {
    dispatch(
      getPricesForOneNft({
        web3Provider: walletService,
        isProfile,
      }),
    );
  }, [walletService, isProfile, dispatch]);

  return (
    <Box className={clsx(classes.root, className)}>
      <Typography className={clsx(classes.priceTitle, 'l text')}>
        {t('Price')}
      </Typography>
      <Typography variant="h1" className={classes.price}>
        {isInfoForMintLoading
          ? <Skeleton width={70} className={classes.skeleton} />
          : new BigNumber(price).multipliedBy(amount).toString()}
        <span className={classes.currency}> BNB</span>
      </Typography>
      <Typography className={clsx('l text', classes.usdPrice)}>
        {isInfoForMintLoading
          ? <Skeleton width={50} className={classes.skeleton} />
          : `$${new BigNumber(usdPrice).multipliedBy(amount).toString()}`}
      </Typography>
      <Typography className={clsx(classes.quantity, 'l text')}>
        {t('Quantity')}
      </Typography>
      <Box className={classes.mintContainer}>
        <Counter max={available} onChange={handleCounterChange} />
        <Button
          variant="contained"
          onClick={buyTokens}
          disabled={!address || isBuyLoading}
        >
          Mint
        </Button>
      </Box>
      <Typography className={clsx(classes.maxAllowed, 'text')}>
        {t('Max allowed ')}
        <span className={classes.maxAmount}>{t('40 per transaction.')}</span>
      </Typography>
    </Box>
  );
};
