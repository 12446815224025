import React, { useEffect, useState, VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useShallowSelector } from 'hooks';
import { MintState, State } from 'types';
import { useDispatch } from 'react-redux';
import { getAvailableLeft } from 'store/mint/actions';
import mintSelector from 'store/mint/selectors';
import { useWalletConnectorContext } from 'services';
import { profileGif, scythesGif } from 'assets/images';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MintSection.styles';
import { AnimatedImages } from '../AnimatedImages';
import { LabeledSwitch } from '../LabeledSwitch';
import { PriceBox } from '../PriceBox';

export interface MintSectionProps {
  className?: string;
  isLoadintMintInfo: boolean;
}

export const MintSection: VFC<MintSectionProps> = ({
  className,
  isLoadintMintInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { walletService } = useWalletConnectorContext();
  const [isProfile, setIsProfile] = useState(true);
  const labels = ['Profile Pic', 'Farming Tool'];
  const { availableLeft } = useShallowSelector<State, MintState>(
    mintSelector.getMint,
  );
  const handleMintChange = () => {
    setIsProfile((prevState) => !prevState);
  };
  useEffect(() => {
    dispatch(
      getAvailableLeft({
        web3Provider: walletService,
        isProfile,
      }),
    );
  }, [walletService, isProfile, dispatch]);
  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.left}>
        <AnimatedImages
          type={isProfile ? 'profile' : 'scythes'}
          image={isProfile ? profileGif : scythesGif}
          className={classes.animatedImg}
        />
        <LabeledSwitch
          firstLabel={labels[0]}
          secondLabel={labels[1]}
          onChange={handleMintChange}
          className={classes.switcher}
        />
      </Box>
      <Box className={classes.right}>
        <Typography variant="h1" className={classes.title}>
          {t(`${isProfile ? labels[0] : labels[1]}`)}
        </Typography>
        <Typography variant="h5" className={clsx(classes.available, 'text')}>
          {t('Available left: ')}
          {isLoadintMintInfo
            ? <Skeleton className={classes.availableSkeleton} width={50} />
            : <span className={classes.availableCounter}>{availableLeft}</span>}
        </Typography>

        <PriceBox
          className={classes.price}
          available={availableLeft}
          isProfile={isProfile}
        />
      </Box>
    </Box>
  );
};
