import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import {
  COLOR_NEW_ACCENT, COLOR_NEW_BORDER, COLOR_NEW_CARD_BG, COLOR_NEW_WHITE,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper('flex-start', 'flex-start'),
    flexDirection: 'column',
    background: COLOR_NEW_CARD_BG,
    border: `1px solid ${COLOR_NEW_BORDER}`,
    borderRadius: 17,
    padding: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  priceTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      lineHeight: '16px !important',
    },
  },
  price: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      fontSize: '28px',
      lineHeight: '34px',
    },
  },
  usdPrice: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      lineHeight: '16px !important',
    },
  },
  currency: {
    color: COLOR_NEW_ACCENT,
    fontFamily: '"Inter", sans-serif',
    fontSize: 25,
    lineHeight: '30px',
  },
  quantity: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      fontSize: '12px !important',
      lineHeight: '16px !important',
    },
  },
  mintContainer: {
    marginTop: theme.spacing(3),
    ...flexHelper('flex-start'),
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      gap: theme.spacing(1),
    },
  },
  maxAllowed: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  maxAmount: {
    marginTop: theme.spacing(3),
    color: COLOR_NEW_WHITE,
  },
  skeleton: {
    display: 'inline-block',
  },
}));
