import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const TelegramLogo: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_376_13795)">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14ZM14.4838 10.4663C13.1707 11.0124 10.5464 12.1428 6.61088 13.8575C5.97181 14.1116 5.63704 14.3602 5.60656 14.6033C5.55506 15.0142 6.06955 15.176 6.77016 15.3963C6.86546 15.4262 6.9642 15.4573 7.06543 15.4902C7.75472 15.7142 8.68193 15.9764 9.16395 15.9868C9.60119 15.9962 10.0892 15.816 10.628 15.446C14.3051 12.9639 16.2032 11.7093 16.3224 11.6822C16.4064 11.6632 16.5229 11.6392 16.6019 11.7093C16.6808 11.7795 16.673 11.9124 16.6647 11.948C16.6137 12.1653 14.5941 14.0429 13.549 15.0145C13.2232 15.3174 12.9921 15.5323 12.9448 15.5813C12.839 15.6913 12.7311 15.7952 12.6275 15.8952C11.9871 16.5125 11.5069 16.9754 12.6541 17.7314C13.2053 18.0947 13.6465 18.3951 14.0866 18.6948C14.5672 19.0221 15.0466 19.3485 15.6668 19.7551C15.8248 19.8587 15.9758 19.9663 16.1227 20.0711C16.6821 20.4698 17.1846 20.8281 17.8054 20.771C18.1662 20.7378 18.5388 20.3986 18.728 19.3869C19.1752 16.996 20.0543 11.8158 20.2575 9.6811C20.2753 9.49407 20.2529 9.25472 20.2349 9.14965C20.2169 9.04457 20.1794 8.89487 20.0428 8.78405C19.881 8.6528 19.6313 8.62513 19.5197 8.62709C19.0119 8.63604 18.2329 8.90692 14.4838 10.4663Z" fill="#FACC14" />
    </g>
    <defs>
      <clipPath id="clip0_376_13795">
        <rect width="27" height="27" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </BaseSVGIcon>
);
