import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_NEW_BLACK_100 } from '../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      width: 325,
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    title: {
      margin: '0 0 16px',
      lineHeight: '54px !important',
      width: '100%',
      '&>h3': {
        textAlign: 'center',
      },
    },
    copyable: {
      backgroundColor: COLOR_NEW_BLACK_100,
    },
    button: {
      // margin: '0 0 16px',
      padding: theme.spacing(1, 1),
      width: '80%',
      margin: '16px auto 0',
      letterSpacing: '0.01em',
      '& + &': {
        marginTop: theme.spacing(2),
      },
      [formatMedia.BREAKPOINT_TABLET]: {
        width: 175,
      },
    },
  });
});
