import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import { isMainnet } from 'config/constants';
import {
  call, CallEffect, PutEffect, SelectEffect,
} from 'redux-saga/effects';
import { validateStatus } from 'utils/validateStatus';

const client: AxiosInstance = axios.create({
  baseURL: isMainnet ? 'https://nft.metarising.io/api/v1' : 'https://microtuber-metaverse.rocknblock.io/api/v1/',
  // baseURL: 'https://cors-anywhere.herokuapp.com/https://nft.metarising.io/api/v1/',
  validateStatus,
});

export default function* ajax(
  config: AxiosRequestConfig,
): Generator<SelectEffect | CallEffect | PutEffect> {
  // @ts-ignore
  const response: AxiosResponse<ApiResponse<unknown>> =
        yield call<(configVar: AxiosRequestConfig) => void>(client, config);

  // if (accessToken && response.status === 401) {
  //     yield put(disconnectWalletState());
  // }

  return response;
}
