import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_NEW_BLACK_100, COLOR_NEW_BORDER } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      maxWidth: 438,
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    title: {
    },
    description: {
      marginTop: theme.spacing(3),
    },
    btns: {
      marginTop: theme.spacing(3),
      ...flexHelper(),
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    btn: {
      width: '100%',
      background: COLOR_NEW_BLACK_100,
      borderColor: COLOR_NEW_BORDER,
      '& img': {
        height: 34,
      },
    },
  });
});
