import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useStyles } from './Benefit.styles';

export interface BenefitProps {
  className?: string;
  Icon:React.FC;
  title:string;
  benefits:string[];
}

export const Benefit: VFC<BenefitProps> = ({
  className, Icon, title, benefits,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={clsx(classes.root, className)}>
      <Icon />
      <Typography variant="h4" className={clsx(classes.title, 'headText')}>
        {t(`${title}`)}
      </Typography>
      <ul className={classes.benefitsList}>
        {benefits.map((benefit, index) => (
          //  eslint-disable-next-line react/no-array-index-key
          <li className={classes.benefit} key={index}>
            <Box className={classes.circle} />
            <Typography
              className={`l ${index === benefits.length - 1 ? 'accent' : ''}`}
            >
              {t(`${benefit}`)}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};
