import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const MediumLogo: React.FC<IconProps> = ({ ...props }) => (
  <BaseSVGIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_376_13803)">
      <path d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z" fill="#FACC14" />
      <path d="M20.9627 11.8277C20.9627 15.377 19.4474 18.2554 17.5783 18.2554C15.7091 18.2554 14.1938 15.377 14.1938 11.8277C14.1938 8.27834 15.7089 5.3999 17.578 5.3999C19.4472 5.3999 20.9625 8.27742 20.9625 11.8277" fill="#FACC14" />
      <path d="M24 11.8276C24 15.0069 23.4672 17.5858 22.8097 17.5858C22.1522 17.5858 21.6196 15.0076 21.6196 11.8276C21.6196 8.64758 22.1525 6.06934 22.8097 6.06934C23.467 6.06934 24 8.64735 24 11.8276Z" fill="#FACC14" />
    </g>
    <defs>
      <clipPath id="clip0_376_13803">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </BaseSVGIcon>
);
