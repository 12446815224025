import { IClaimFromBackend } from 'types/store/claims';
import { call, put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from '../actionTypes';
import { getUserClaims } from '../actions';
import { updateClaims } from '../reducer';

export function* getUserClaimsSaga({
  type,
  payload: { address },
}: ReturnType<typeof getUserClaims>) {
  yield put(apiActions.request(type));
  try {
    const { data } = yield call(baseApi.getAllUserClaims, {
      userAddress: address,
    });

    yield put(
      updateClaims(data.filter((claim: IClaimFromBackend) => !claim.is_claimed)),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_CLAIMS, getUserClaimsSaga);
}
