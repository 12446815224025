import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const ProfilePicIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    {...props}
  >
    <path d="M28 3C41.7431 3 53 14.2569 53 28C53 41.7431 41.7431 53 28 53C14.2569 53 3 41.7431 3 28C3 14.2569 14.2569 3 28 3Z" fill="#353535" stroke="#FACC14" strokeWidth="6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.9759 4.08654C20.7461 4.53964 15.7874 6.84437 11.9581 10.6018C5.45832 16.9796 3.30385 26.4591 6.38064 35.1422C7.17848 37.3939 8.72794 40.0949 10.3564 42.0726C11.1182 42.9981 13.0098 44.8934 13.1714 44.8934C13.2203 44.8934 13.4096 44.7727 13.5923 44.6251C13.7748 44.4776 15.6038 43.4336 17.6567 42.3052C19.9584 41.04 21.52 40.1258 21.7308 39.9203C21.9186 39.737 22.1817 39.3624 22.3154 39.088C22.5494 38.6074 22.5594 38.5261 22.5867 36.8657L22.6151 35.1422L22.0118 34.2351C21.2286 33.0575 20.5224 31.5992 20.116 30.3208C19.8413 29.4567 19.731 29.2353 19.4028 28.8898C18.8154 28.2715 18.7359 27.8917 18.7359 25.7034C18.7359 23.6809 18.7972 23.3491 19.2836 22.7374L19.5544 22.397L19.5421 19.4265C19.5307 16.6522 19.5425 16.4123 19.72 15.7904C20.4997 13.0608 22.7499 11.461 26.3617 11.0688C27.5334 10.9415 28.4657 10.9415 29.6374 11.0688C33.2782 11.4642 35.5707 13.1133 36.2875 15.8526C36.4531 16.4858 36.4681 16.804 36.4573 19.4724L36.4455 22.3979L36.7245 22.748C37.1861 23.3274 37.2632 23.7571 37.2632 25.7517C37.2632 27.7902 37.1811 28.2213 36.6804 28.8113C36.506 29.0167 36.1875 29.2706 35.9727 29.3756C35.6493 29.5335 35.5626 29.6324 35.4689 29.9505C35.2984 30.5295 34.5603 32.1573 34.143 32.8745C33.9398 33.2237 33.527 33.8564 33.2257 34.2805L32.6779 35.0515L32.678 36.8657C32.6781 38.5918 32.6886 38.7026 32.8946 39.1489C33.2962 40.0188 33.5248 40.167 37.767 42.3082C39.933 43.4013 41.9403 44.4542 42.2279 44.6479L42.7507 45L43.1806 44.637C43.8563 44.0663 45.4345 42.384 46.0884 41.5372C50.1799 36.2387 51.8146 29.5394 50.6162 22.9824C49.3368 15.9832 44.9189 9.94997 38.6572 6.6508C34.7419 4.58789 30.377 3.70529 25.9759 4.08654Z" fill="#FACC14" />
  </BaseSVGIcon>
);
