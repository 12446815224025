import { URL } from 'appConstants';
import ajax from './ajax';

export const baseApi = {
  getAllUserClaims(data: { userAddress: string }) {
    return ajax({
      method: 'get',
      url: URL.getAllUserClaims(data.userAddress),
    });
  },
  getDataForClaims(data: {contractAddress: string, userAddress: string}) {
    const { contractAddress, userAddress } = data;
    return ajax({
      method: 'get',
      url: URL.getDataForClaims(contractAddress, userAddress),
    });
  },
  getRandomTokenIds(data:{count:number, address:string}) {
    const { address, count } = data;
    return ajax({
      method: 'get',
      url: URL.getRandomTokenIds(address),
      params: {
        count,
      },
    });
  },
  getTokensMetadata(data: {contractAddress: string, tokensId: Array<string>}) {
    const { contractAddress, tokensId } = data;
    return ajax({
      method: 'get',
      url: URL.getTokensMetadata(contractAddress, tokensId),
    });
  },
};
