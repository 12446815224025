import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import {
  COLOR_NEW_ACCENT, COLOR_NEW_ACCENT_BG, COLOR_NEW_BORDER_2, COLOR_NEW_CARD_BG,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      background: COLOR_NEW_CARD_BG,
      border: `1px solid ${COLOR_NEW_BORDER_2}`,
      borderRadius: 26,
      maxWidth: '424px',
      width: '100%',
      overflow: 'hidden',
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    secondary: {
      background: 'transparent',
      border: 'none',
      width: '100%',
      maxWidth: '380px',
      borderRadius: 14,
      '& $switch': {
        gap: 0,
      },
      '& $label': {
        border: `1px solid ${COLOR_NEW_BORDER_2}`,
        width: '50%',
        borderRadius: 14,
        padding: '22px 0',
        transition: 'all 0.5s',
        fontSize: '16px',
        lineHeight: '19px',
      },
      '& p:first-of-type': {
        borderRight: '1px solid transparent',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& p:last-of-type': {
        borderLeft: '1px solid transparent',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& $checkedBackground': {
        display: 'none',
      },
      '& p.accent': {
        background: COLOR_NEW_ACCENT_BG,
        border: `1px solid ${COLOR_NEW_ACCENT}`,
      },
    },
    switch: {
      ...flexHelper('center'),
      position: 'relative',
      cursor: 'pointer',
    },
    label: {
      background: 'transparent',
      padding: '11px 0',
      zIndex: 1,
      width: '50%',
      textAlign: 'center',
      userSelect: 'none',
      fontSize: '18px',
      lineHeight: '30px',
      [theme.breakpoints.down('md')]: {
        padding: '8px 0',
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    input: {
      display: 'none',
    },
    checkedBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 'unset',
      backgroundColor: COLOR_NEW_ACCENT,
      borderRadius: 26,
      width: '54%',
      height: '100%',
      zIndex: 0,
      transition: 'all 0.5s',
    },
    checked: {
      left: '100%',
      transform: 'translateX(-100%)',
    },
  });
});
