// eslint-disable-next-line no-shadow
export enum Proportions {
  p1to1 = 1,
  p3to4 = 3 / 4,
  p4to3 = 4 / 3,
}

export interface CustomImageProps {
  width?: number;
  proportions?: number;
  src?: string;
  alt?: string;
  title?: string;
  className?: string;
}
