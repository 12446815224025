import { fork } from 'redux-saga/effects';

import userSaga from 'store/user/sagas';
import pricesSaga from 'store/prices/sagas';
import mintSaga from 'store/mint/sagas';
import claimsSagas from 'store/claims/sagas';
import modalsSagas from 'store/modals/sagas';

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(pricesSaga);
  yield fork(mintSaga);
  yield fork(claimsSagas);
  yield fork(modalsSagas);
}
