import { Theme } from '@material-ui/core';
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

export const breakpointOptions: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 320, sm: 600, md: 800, lg: 1153, xl: 1680,
  },
};

export const getMuiGrid = (theme: Theme): Overrides['MuiGrid'] => ({
  item: {
    padding: theme.spacing(1),
  },
});

export const getMuiGridDefaultProps = (): ComponentsProps['MuiGrid'] => ({});
