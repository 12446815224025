import React, { VFC } from 'react';

import { Button } from '@material-ui/core';
import { Languages } from 'types';
import clsx from 'clsx';
import { useStyles } from './ChangeLanguageModal.styles';
import { Modal, ModalProps } from '../Modal';

export interface ChangeLanguageModalProps extends ModalProps {
  changeLanguage:(language:Languages)=>void;
  selectedLanguage: Languages;
}

export const ChangeLanguageModal: VFC<ChangeLanguageModalProps> = ({
  open,
  onClose,
  changeLanguage,
  selectedLanguage,
  ...otherModalProps
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      customTitle="Change language"
      titleClassName={classes.title}
      withCloseIcon
      paperClassName={classes.root}
      size="sm"
      {...otherModalProps}
    >
      <Button
        onClick={() => changeLanguage(Languages.en)}
        className={clsx({ [classes.selected]: selectedLanguage === Languages.en }, classes.btn)}
      >
        English
      </Button>
      <Button
        onClick={() => changeLanguage(Languages.kr)}
        className={clsx({ [classes.selected]: selectedLanguage === Languages.kr }, classes.btn)}
      >
        한국어
      </Button>
    </Modal>
  );
};
