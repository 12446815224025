import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { WalletProviders } from 'types';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ontoLogo } from 'assets/images';
import { MetaMaskLogo, WalletConnectLogo } from 'theme/icons';
import { useStyles } from './ConnectWalletModal.styles';

export interface ConnectWalletModalProps extends ModalProps {
  connectWallet?: (wallet: WalletProviders) => void;
}

export const ConnectWalletModal: VFC<ConnectWalletModalProps> = ({
  open,
  onClose,
  connectWallet,
  ...otherModalProps
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      // className={}
      customTitle="Connect wallet"
      titleClassName={classes.title}
      withCloseIcon
      paperClassName={classes.root}
      size="sm"
      {...otherModalProps}
    >
      <Typography className={clsx(classes.description, 'text')}>
        Please link crypto-wallet in order to sign in.
      </Typography>
      <Box className={classes.btns}>
        <Button
          onClick={() => connectWallet(WalletProviders.metamask)}
          className={classes.btn}
        >
          <MetaMaskLogo />
        </Button>
        <Button
          onClick={() => connectWallet(WalletProviders.onto)}
          className={classes.btn}
        >
          <img src={ontoLogo} alt="onto" />
        </Button>
        <Button
          onClick={() => connectWallet(WalletProviders.walletConnect)}
          className={classes.btn}
        >
          <WalletConnectLogo />
        </Button>
      </Box>
    </Modal>
  );
};
